<template>
    <v-container class="pa-8">
        <div class="text-h5 font-weight-bold grey--text text--darken-2 mb-4" v-if="$route.path == '/risklevel/'+$route.params.id">
            <v-skeleton-loader
                type="table-heading"
                v-if="ORMP.length === 0"
                 >
            </v-skeleton-loader>
                
                <span v-else>
                    <router-link :to="{name: 'ViewAllORMP', params: {id: this.ORMP.CoverageId}}" v-if="isAddNew === false && isEdit === false"><v-icon>mdi mdi-arrow-left</v-icon></router-link>
                    <v-icon class="ml-1">mdi-flag-variant</v-icon>
                    {{ORMP.RiskName.RiskName}}
                </span>
                
            
            <v-btn
                v-if="isAddNew || isEdit"
                @click="cancel"
                color="primary"
                dark
                class="float-right"
                >
                Cancel
            </v-btn>
            <v-btn
                v-else
                @click="toggleAdd"
                color="primary"
                dark
                class="float-right"   
                >
                <v-icon>mdi-plus</v-icon>
                New Row
            </v-btn>
        </div>
        
        <div class="text-h5 font-weight-bold grey--text text--darken-2 mb-4" v-else>
            <v-skeleton-loader
                type="table-heading"
                v-if="PRMP.length === 0"
                 >
            </v-skeleton-loader>
                
                <span v-else>
                    <router-link :to="{name: 'ViewAllPRMP', params: {id: this.PRMP.CoverageId}}" v-if="isAddNew === false && isEdit === false"><v-icon>mdi mdi-arrow-left</v-icon></router-link>
                    <v-icon class="ml-1">mdi-flag-variant</v-icon>
                    {{PRMP.RiskName.RiskName}}
                </span>
                
            
            <v-btn
                v-if="isAddNew || isEdit"
                @click="cancel"
                color="primary"
                dark
                class="float-right"
                >
                Cancel
            </v-btn>
            <v-btn
                v-else
                @click="toggleAdd"
                color="primary"
                dark
                class="float-right"   
                >
                <v-icon>mdi-plus</v-icon>
                New Row
            </v-btn>
        </div>
    
        <v-row class="mt-6" v-if="$route.path == '/risklevel/'+$route.params.id"> 
            <v-col lg="4" v-if="isEdit == false && isAddNew == false">
                <v-card v-if="tableValues.length != 0 ">

                    <v-card-text v-if="ORMP.length === 0 && tableValues.length === 0">
                    <v-skeleton-loader
                        type="table-heading, list-item-two-line"
                        >
                    </v-skeleton-loader>
                    </v-card-text>   

                    <v-card-text v-else>
                        <div>
                            <h2>Risk Classification</h2>
                            <p>{{ORMP.RiskClassification.RiskClassification}}</p>
                            <hr>
                            <h2 class="pt-2">Objective</h2>
                            <v-list-item v-for="(objective, index) in this.ORMP.Objectives" :key="index">{{objective}}</v-list-item>
                            <hr>
                            <h2 class="pt-2">Likelihood Level</h2>
                            <p v-if="tableValues[0].LikelihoodLevel == 'N/A'">N/A</p>
                            <p v-else>{{tableValues[0].LikelihoodLevel.Level}}</p>
                            <hr>
                            <h2 class="pt-2">Impact Level</h2>
                            <p v-if="tableValues[0].ImpactLevel == 'N/A'">N/A</p>
                            <p v-else>{{tableValues[0].ImpactLevel.Level}}</p>
                            <hr>
                            <h2 class="pt-2">Vulnerability</h2>
                            <p>{{tableValues[0].Vulnerability}}</p>
                            <hr>
                            <h2 class="pt-2">Remarks</h2>
                            <p v-if="tableValues[0].ImpactClassification == 'N/A'">N/A</p>
                            <p v-else>{{tableValues[0].ImpactClassification.name}}</p>
                            <hr>
                            <h2 class="pt-2">Risk Strategy</h2>
                            <p v-if="tableValues[0].RiskStrategy == 'N/A'">N/A</p>
                            <p>{{tableValues[0].RiskStrategy.RiskStrategy}}</p>
                            <hr>
                            <h2 class="pt-2">Risk Treatment Owner</h2>
                            <p v-if="tableValues[0].RiskTreatmentOwner == 'N/A'">N/A</p>
                            <p><span v-for="(riskowner, index) in tableValues[0].RiskTreatmentOwner" :key="index">{{riskowner.RiskTreatmentOwner}}<span v-if="index != (tableValues[0].RiskTreatmentOwner.length -1)">, </span></span></p>
                            <div v-if="tableValues[0].KPI">
                                <hr>
                                <h2 class="pt-2">KPI</h2>
                                 <v-list-item v-for="(kpi, index) in tableValues[0].KPI" :key="index">{{kpi}}</v-list-item>
                            </div>
                            <div v-if="tableValues[0].TimelineEndDate">
                                <hr>
                                <h2 class="pt-2">Timeline</h2>
                                <p v-if="tableValues[0].TimelineStartDate == 'N/A'">N/A</p>
                                <p v-else>{{tableValues[0].TimelineStartDate}} - {{tableValues[0].TimelineEndDate}}</p>
                            </div>
                            <div v-if="tableValues[0].Budget">
                                <hr>
                                <h2 class="pt-2">Budget/Resources</h2>
                                <v-list-item v-for="(budget, index) in tableValues[0].Budget" :key="index">{{budget}}</v-list-item>
                            </div>
                        </div>
                    
                        <v-expansion-panels 
                            class="pa-1"
                            multiple
                            v-model="panel"
                            >  
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    Causes
                                </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-row>
                                            <v-col>
                                                <v-list v-for="(item, index) in this.ORMP.Causes"
                                                    :key="index">
                                                    <v-list-item class="">
                                                       {{index+1}} - {{item}}
                                                    </v-list-item>
                                                </v-list>
                                            </v-col>
                                        </v-row>
                                    </v-expansion-panel-content>
                            </v-expansion-panel>

                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    Impact
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-row>
                                        <v-col>
                                            <v-list v-for="(item, index) in this.ORMP.Impacts"
                                                :key="index">
                                                <v-list-item class="">
                                                    •{{item}}
                                                </v-list-item>
                                            </v-list>
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        
                            <v-expansion-panel v-if="this.tableValues[0].RiskActions">
                                <v-expansion-panel-header>
                                    Risk Action
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-row>
                                        <v-col>
                                            <v-col>
                                                 <div v-if="this.tableValues[0].RiskActions == 'N/A'">
                                                    <v-list-item> 
                                                        <v-col>
                                                            <span>N/A</span>
                                                        </v-col>
                                                    </v-list-item>
                                                </div>
                                                <div 
                                                    v-else
                                                    v-for="(riskaction, index) in this.tableValues[0].RiskActions"
                                                    :key="index">
                                                    <v-list-item> 
                                                        <v-col>
                                                        <span 
                                                        v-for="(cause, index) in riskaction.causes"
                                                        :key="index">
                                                             {{cause.value+1}}<span v-if="index != (riskaction.causes.length - 1)">,</span></span> 
                                                        </v-col>
                                                    <v-col>{{riskaction.text}}</v-col>
                                                    </v-list-item>
                                                </div>
                                            </v-col>
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>

                    </v-card-text>
                </v-card>

                <v-card v-else>
                    <v-card-text v-if="ORMP.length === 0 || ORMPLevelsInherent.length === 0">
                    <v-skeleton-loader
                        type="table-heading, list-item-two-line"
                        >
                    </v-skeleton-loader>
                    </v-card-text>   

                    <v-card-text v-else>
                        <h2>Risk Classification</h2>
                        <p>{{ORMP.RiskClassification.RiskClassification}}</p>
                        <hr>
                        <h2 class="pt-2">Objective</h2>
                        <v-list-item v-for="(objective, index) in this.ORMP.Objectives" :key="index">{{objective}}</v-list-item>
                        <hr>
                        <h2 class="pt-2">Likelihood Level</h2>
                        <p>{{ORMPLevelsInherent[0].LikelihoodLevel.Level}}</p>
                        <hr>
                        <h2 class="pt-2">Impact Level</h2>
                        <p>{{ORMPLevelsInherent[0].ImpactLevel.Level}}</p>
                        <hr>
                        <h2 class="pt-2">Vulnerability</h2>
                        <p>{{ORMPLevelsInherent[0].Vulnerability}}</p>
                        <hr>
                        <h2 class="pt-2">Remarks</h2>
                        <p>{{ORMPLevelsInherent[0].ImpactClassification.name}}</p>
                        <hr>
                        <h2 class="pt-2">Risk Strategy</h2>
                        <p>{{ORMPLevelsInherent[0].RiskStrategy.RiskStrategy}}</p>
                        <hr>
                         <h2 class="pt-2">Risk Treatment Owner</h2>
                        <p><span v-for="(riskowner, index) in ORMPLevelsInherent[0].RiskTreatmentOwner" :key="index">{{riskowner.RiskTreatmentOwner}}<span v-if="index != (ORMPLevelsInherent[0].RiskTreatmentOwner.length -1)">, </span></span></p>
                        
                        <v-expansion-panels 
                            class="pa-1"
                            multiple
                            v-model="panel"
                            >  
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    Causes
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-row>
                                        <v-col>
                                            <v-list v-for="(item, index) in this.ORMP.Causes"
                                                :key="index">
                                                <v-list-item class="">
                                                    {{index+1}} - {{item}}
                                                </v-list-item>
                                            </v-list>
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel>

                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    Impact
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-row>
                                        <v-col>
                                            <v-list v-for="(item, index) in this.ORMP.Impacts"
                                                :key="index">
                                                <v-list-item class="">
                                                    •{{item}}
                                                </v-list-item>
                                            </v-list>
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel>

                            <!-- <v-expansion-panel>
                                <v-expansion-panel-header>
                                    Risk Action
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                        <v-row>
                                            <v-col>
                                                <v-col>
                                                    <div 
                                                        v-for="(riskaction, index) in this.ORMPLevelsInherent[0].RiskActions"
                                                        :key="index">
                                                        <v-list-item> 
                                                            <v-col>
                                                                <span 
                                                                v-for="(cause, index) in riskaction.causes"
                                                                :key="index">
                                                                {{cause.value+1}}<span v-if="index != (riskaction.causes.length - 1)">,</span></span> 
                                                            </v-col>
                                                            <v-col>{{riskaction.text}}</v-col>
                                                        </v-list-item>
                                                    </div>
                                                </v-col>
                                            </v-col>
                                        </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel> -->
                        </v-expansion-panels>
                    </v-card-text>
                </v-card>
             </v-col>

            <v-col v-if="isEdit == false && isAddNew == false">

                    <v-skeleton-loader
                        type="table-heading, image"
                        v-if="returnRMPLevels().length === 0"
                    ></v-skeleton-loader>


                <v-data-table
                    :headers="headers"
                    :items="returnRMPLevels()"
                    :items-per-page="5"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    v-model="tableValues"
                    :single-select="singleSelect"
                    item-key=".key"
                    class="elevation-1"
                    show-select
                    v-else
                    >
                
                    <template v-slot:item.actions="{ item }">
                        <v-row class="ml-1" v-if="item.RiskManagementLevel !== 'Inherent'">
                            <v-icon 
                                small
                                @click="editRiskLevel(item)"
                                >
                                mdi-pencil
                            </v-icon>
                            <v-icon
                                small
                                @click="deleteItem(item)"
                                >
                                mdi-delete
                            </v-icon>
                        </v-row>
                    </template>

                    <template v-slot:item.CreatedAt="{ item }">
                        {{$moment(item.CreatedAt.toDate()).format(' MMMM DD, YYYY / h:mm A')}}
                    </template>
                </v-data-table>
            </v-col>
        </v-row>

         <v-row class="mt-6" v-else> 
            <v-col lg="4" v-if="isEdit == false && isAddNew == false">
                <v-card v-if="tableValues.length != 0 ">

                    <v-card-text v-if="PRMP.length === 0 && tableValues.length === 0">
                    <v-skeleton-loader
                        type="table-heading, list-item-two-line"
                        >
                    </v-skeleton-loader>
                    </v-card-text>   

                    <v-card-text v-else>
                        <div>
                            <h2>Risk Classification</h2>
                            <p>{{PRMP.RiskClassification.RiskClassification}}</p>
                            <hr>
                            <h2 class="pt-2">Objective</h2>
                            <v-list-item v-for="(objective, index) in this.PRMP.Objectives" :key="index">{{objective}}</v-list-item>
                            <hr>
                            <h2 class="pt-2">Likelihood Level</h2>
                            <p>{{tableValues[0].LikelihoodLevel.Level}}</p>
                            <hr>
                            <h2 class="pt-2">Impact Level</h2>
                            <p>{{tableValues[0].ImpactLevel.Level}}</p>
                            <hr>
                            <h2 class="pt-2">Vulnerability</h2>
                            <p>{{tableValues[0].Vulnerability}}</p>
                            <hr>
                            <h2 class="pt-2">Remarks</h2>
                            <p>{{tableValues[0].ImpactClassification.name}}</p>
                            <hr>
                            <h2 class="pt-2">Risk Strategy</h2>
                            <p>{{tableValues[0].RiskStrategy.RiskStrategy}}</p>
                            <hr>
                            <h2 class="pt-2">Risk Treatment Owner</h2>
                            <p><span v-for="(riskowner, index) in tableValues[0].RiskTreatmentOwner" :key="index">{{riskowner.RiskTreatmentOwner}}<span v-if="index != (tableValues[0].RiskTreatmentOwner.length -1)">, </span></span></p>
                            <div v-if="tableValues[0].KPI">
                                <hr>
                                <h2 class="pt-2">KPI</h2>
                                 <v-list-item v-for="(kpi, index) in tableValues[0].KPI" :key="index">{{kpi}}</v-list-item>
                            </div>
                            <div v-if="tableValues[0].TimelineEndDate">
                                <hr>
                                <h2 class="pt-2">Timeline</h2>
                                <p>{{tableValues[0].TimelineStartDate}} - {{tableValues[0].TimelineEndDate}}</p>
                            </div>
                            <div v-if="tableValues[0].Budget">
                                <hr>
                                <h2 class="pt-2">Budget/Resources</h2>
                                <v-list-item v-for="(budget, index) in tableValues[0].Budget" :key="index">{{budget}}</v-list-item>
                            </div>
                        </div>
                    
                        <v-expansion-panels 
                            class="pa-1"
                            multiple
                            v-model="panel"
                            >  
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    Causes
                                </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-row>
                                            <v-col>
                                                <v-list v-for="(item, index) in this.PRMP.Causes"
                                                    :key="index">
                                                    <v-list-item class="">
                                                       {{index+1}} - {{item}}
                                                    </v-list-item>
                                                </v-list>
                                            </v-col>
                                        </v-row>
                                    </v-expansion-panel-content>
                            </v-expansion-panel>

                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    Impact
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-row>
                                        <v-col>
                                            <v-list v-for="(item, index) in this.PRMP.Impacts"
                                                :key="index">
                                                <v-list-item class="">
                                                    •{{item}}
                                                </v-list-item>
                                            </v-list>
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        
                            <v-expansion-panel v-if="this.tableValues[0].RiskActions">
                                <v-expansion-panel-header>
                                    Risk Action
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-row>
                                        <v-col>
                                            <v-col>
                                                <div 
                                                    v-for="(riskaction, index) in this.tableValues[0].RiskActions"
                                                    :key="index">
                                                    <v-list-item> 
                                                        <v-col>
                                                        <span 
                                                        v-for="(cause, index) in riskaction.causes"
                                                        :key="index">
                                                             {{cause.value+1}}<span v-if="index != (riskaction.causes.length - 1)">,</span></span> 
                                                        </v-col>
                                                    <v-col>{{riskaction.text}}</v-col>
                                                    </v-list-item>
                                                </div>
                                            </v-col>
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>

                    </v-card-text>
                </v-card>

                <v-card v-else>
                    <v-card-text v-if="PRMP.length === 0 || PRMPLevelsInherent.length === 0">
                    <v-skeleton-loader
                        type="table-heading, list-item-two-line"
                        >
                    </v-skeleton-loader>
                    </v-card-text>   

                    <v-card-text v-else>
                        <h2>Risk Classification</h2>
                        <p>{{PRMP.RiskClassification.RiskClassification}}</p>
                        <hr>
                        <h2 class="pt-2">Objective</h2>
                        <v-list-item v-for="(objective, index) in this.PRMP.Objectives" :key="index">{{objective}}</v-list-item>
                        <hr>
                        <h2 class="pt-2">Likelihood Level</h2>
                        <p>{{PRMPLevelsInherent[0].LikelihoodLevel.Level}}</p>
                        <hr>
                        <h2 class="pt-2">Impact Level</h2>
                        <p>{{PRMPLevelsInherent[0].ImpactLevel.Level}}</p>
                        <hr>
                        <h2 class="pt-2">Vulnerability</h2>
                        <p>{{PRMPLevelsInherent[0].Vulnerability}}</p>
                        <hr>
                        <h2 class="pt-2">Remarks</h2>
                        <p>{{PRMPLevelsInherent[0].ImpactClassification.name}}</p>
                        <hr>
                        <h2 class="pt-2">Risk Strategy</h2>
                        <p>{{PRMPLevelsInherent[0].RiskStrategy.RiskStrategy}}</p>
                        <hr>
                         <h2 class="pt-2">Risk Treatment Owner</h2>
                        <p><span v-for="(riskowner, index) in PRMPLevelsInherent[0].RiskTreatmentOwner" :key="index">{{riskowner.RiskTreatmentOwner}}<span v-if="index != (PRMPLevelsInherent[0].RiskTreatmentOwner.length -1)">, </span></span></p>
                        
                        <v-expansion-panels 
                            class="pa-1"
                            multiple
                            v-model="panel"
                            >  
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    Causes
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-row>
                                        <v-col>
                                            <v-list v-for="(item, index) in this.PRMP.Causes"
                                                :key="index">
                                                <v-list-item class="">
                                                    {{index+1}} - {{item}}
                                                </v-list-item>
                                            </v-list>
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel>

                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    Impact
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-row>
                                        <v-col>
                                            <v-list v-for="(item, index) in this.PRMP.Impacts"
                                                :key="index">
                                                <v-list-item class="">
                                                    •{{item}}
                                                </v-list-item>
                                            </v-list>
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel>

                            <!-- <v-expansion-panel>
                                <v-expansion-panel-header>
                                    Risk Action
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                        <v-row>
                                            <v-col>
                                                <v-col>
                                                    <div 
                                                        v-for="(riskaction, index) in this.ORMPLevelsInherent[0].RiskActions"
                                                        :key="index">
                                                        <v-list-item> 
                                                            <v-col>
                                                                <span 
                                                                v-for="(cause, index) in riskaction.causes"
                                                                :key="index">
                                                                {{cause.value+1}}<span v-if="index != (riskaction.causes.length - 1)">,</span></span> 
                                                            </v-col>
                                                            <v-col>{{riskaction.text}}</v-col>
                                                        </v-list-item>
                                                    </div>
                                                </v-col>
                                            </v-col>
                                        </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel> -->
                        </v-expansion-panels>
                    </v-card-text>
                </v-card>
             </v-col>

            <v-col v-if="isEdit == false && isAddNew == false">

                    <v-skeleton-loader
                        type="table-heading, image"
                        v-if="returnRMPLevels().length === 0"
                    ></v-skeleton-loader>


                <v-data-table
                    :headers="headers"
                    :items="returnRMPLevels()"
                    :items-per-page="5"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    v-model="tableValues"
                    :single-select="singleSelect"
                    item-key=".key"
                    class="elevation-1"
                    show-select
                    v-else
                    >
                
                    <template v-slot:item.actions="{ item }">
                        <v-row class="ml-1" v-if="item.RiskManagementLevel !== 'Inherent'">
                            <v-icon 
                                small
                                @click="editRiskLevel(item)"
                                >
                                mdi-pencil
                            </v-icon>
                            <v-icon
                                small
                                @click="deleteItem(item)"
                                >
                                mdi-delete
                            </v-icon>
                        </v-row>
                    </template>

                    <template v-slot:item.CreatedAt="{ item }">
                        {{$moment(item.CreatedAt.toDate()).format(' MMMM DD, YYYY / h:mm A')}}
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
                
        <v-row v-if="isAddNew && $route.path == '/risklevel/'+$route.params.id">
            <v-col lg="4">
                <v-select
                    v-if="isAddNew"
                    label="Show Previous Plans"
                    item-text="RiskManagementLevel"
                    item-value='mapORMP'
                    outlined
                    return-object
                    :items="returnRMPLevels()"
                    v-model="selectedPreviousPlan"
                    >
                </v-select>

                <v-card v-if="selectedPreviousPlan">   
                    <v-card-text v-if="selectedPreviousPlan.length === 0">
                        <v-skeleton-loader
                            type="table-heading, list-item-two-line"
                            >
                        </v-skeleton-loader>
                    </v-card-text>   
                           
                    <v-card-text v-else>
                        <h2>Risk Classification</h2>
                        <p>{{ORMP.RiskClassification.RiskClassification}}</p>
                        <hr>
                        <h2 class="pt-2">Objective</h2>
                        <v-list-item v-for="(objective, index) in this.ORMP.Objectives" :key="index">{{objective}}</v-list-item>
                        <hr>
                        <h2 class="pt-2">Likelihood Level</h2>
                        <p>{{selectedPreviousPlan.LikelihoodLevel.Level}}</p>
                        <hr>
                        <h2 class="pt-2">Impact Level</h2>
                        <p>{{selectedPreviousPlan.ImpactLevel.Level}}</p>
                        <hr>
                        <h2 class="pt-2">Vulnerability</h2>
                        <p>{{selectedPreviousPlan.Vulnerability}}</p>
                        <hr>
                        <h2 class="pt-2">Remarks</h2>
                        <p>{{selectedPreviousPlan.ImpactClassification.name}}</p>
                        <hr>
                        <h2 class="pt-2">Risk Strategy</h2>
                        <p>{{selectedPreviousPlan.RiskStrategy.RiskStrategy}}</p>
                        <div v-if="selectedPreviousPlan.KPI">
                            <hr>
                            <h2 class="pt-2">KPI</h2>
                            <v-list-item v-for="(kpi, index) in selectedPreviousPlan.KPI" :key="index">{{kpi}}</v-list-item>
                        </div>
                        <div v-if="selectedPreviousPlan.TimelineEndDate">
                            <hr>
                            <h2 class="pt-2">Timeline</h2>
                            <p>{{selectedPreviousPlan.TimelineStartDate}} - {{selectedPreviousPlan.TimelineEndDate}}</p>
                        </div>
                        <div v-if="selectedPreviousPlan.Budget">
                            <hr>
                            <h2 class="pt-2">Budget/Resources</h2>
                            <v-list-item v-for="(budget, index) in selectedPreviousPlan.Budget" :key="index">{{budget}}</v-list-item>
                        </div>
                        <v-expansion-panels 
                            class="pa-1"
                            multiple
                            v-model="panel"
                            >
                          <v-expansion-panel>
                                <v-expansion-panel-header>
                                    Causes
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-row>
                                        <v-col>
                                            <v-list v-for="(item, index) in this.ORMP.Causes"
                                                :key="index">
                                                <v-list-item class="">
                                                    {{index+1}} - {{item}}
                                                </v-list-item>
                                            </v-list>
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel>

                            <v-expansion-panel>
                            <v-expansion-panel-header>
                                Impacts
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-row>
                                    <v-col>
                                        <v-list v-for="(item, index) in this.ORMP.Impacts"
                                        :key="index">
                                            <v-list-item class="">
                                                •{{item}}
                                            </v-list-item>
                                         </v-list>
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-content>
                            </v-expansion-panel>

                            <v-expansion-panel v-if="selectedPreviousPlan.RiskManagementLevel !== 'Inherent'">
                            <v-expansion-panel-header>
                                Risk Action
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-row>
                                    <v-col>
                                        <v-col>
                                            <div 
                                            v-for="(riskaction, index) in this.selectedPreviousPlan.RiskActions"
                                            :key="index">
                                                <v-list-item> 
                                                    <v-col>
                                                        <span 
                                                        v-for="(cause, index) in riskaction.causes"
                                                        :key="index">
                                                             {{cause.value+1}}<span v-if="index != (riskaction.causes.length - 1)">,</span></span> 
                                                    </v-col>
                                                    <v-col>{{riskaction.text}}</v-col>
                                                </v-list-item>
                                            </div>
                                        </v-col>
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-card-text>
                </v-card>
                <v-card v-else>

                    
                 <v-card-text v-if="ORMP.length === 0 && ORMPLevelsInherent.length === 0">
                    <v-skeleton-loader
                        type="table-heading, list-item-two-line"
                        >
                    </v-skeleton-loader>
                    </v-card-text>   


                    <v-card-text v-else>
                        <h2>Risk Classification</h2>
                        <p>{{ORMP.RiskClassification.RiskClassification}}</p>
                        <hr>
                        <h2 class="pt-2">Objective</h2>
                        <v-list-item v-for="(objective, index) in this.ORMP.Objectives" :key="index">{{objective}}</v-list-item>
                        <hr>
                        <h2 class="pt-2">Likelihood Level</h2>
                        <p>{{ORMPLevelsInherent[0].LikelihoodLevel.Level}}</p>
                        <hr>
                        <h2 class="pt-2">Impact Level</h2>
                        <p>{{ORMPLevelsInherent[0].ImpactLevel.Level}}</p>
                        <hr>
                        <h2 class="pt-2">Vulnerability</h2>
                        <p>{{ORMPLevelsInherent[0].Vulnerability}}</p>
                        <hr>
                        <h2 class="pt-2">Remarks</h2>
                        <p>{{ORMPLevelsInherent[0].ImpactClassification.name}}</p>
                        <hr>
                        <h2 class="pt-2">Risk Strategy</h2>
                        <p>{{ORMPLevelsInherent[0].RiskStrategy.RiskStrategy}}</p>
                        
                        <v-expansion-panels 
                            class="pa-1"
                            multiple
                            v-model="panel"
                            >  
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    Causes
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-row>
                                        <v-col>
                                            <v-list v-for="(item, index) in this.ORMP.Causes"
                                                :key="index">
                                                <v-list-item class="">
                                                    {{index+1}} - {{item}}
                                                </v-list-item>
                                            </v-list>
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel>

                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    Impact
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-row>
                                        <v-col>
                                            <v-list v-for="(item, index) in this.ORMP.Impacts"
                                                :key="index">
                                                <v-list-item class="">
                                                    •{{item}}
                                                </v-list-item>
                                            </v-list>
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <!-- 
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    Risk Action
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                        <v-row>
                                            <v-col>
                                                <v-col>
                                                    <div 
                                                        v-for="(riskaction, index) in this.ORMPLevelsInherent[0].RiskActions"
                                                        :key="index">
                                                        <v-list-item> 
                                                            <v-col>
                                                                <span 
                                                                v-for="(cause, index) in riskaction.causes"
                                                                :key="index">
                                                                {{cause.value+1}}<span v-if="index != (riskaction.causes.length - 1)">,</span></span> 
                                                            </v-col>
                                                            <v-col>{{riskaction.text}}</v-col>
                                                        </v-list-item>
                                                    </div>
                                                </v-col>
                                            </v-col>
                                        </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel> -->
                        </v-expansion-panels>
                    </v-card-text>  
                </v-card>    
            </v-col>
            <v-col lg="8">
                <RiskManagementStepper :id="this.$route.params.id" @clicked="save"/>
            </v-col> 
        </v-row>
         <v-row v-if="isAddNew && $route.path !== '/risklevel/'+$route.params.id">
            <v-col lg="4">
                <v-select
                    v-if="isAddNew"
                    label="Show Previous Plans"
                    item-text="RiskManagementLevel"
                    item-value='mapORMP'
                    outlined
                    return-object
                    :items="returnRMPLevels()"
                    v-model="selectedPreviousPlan"
                    >
                </v-select>

                <v-card v-if="selectedPreviousPlan">   
                    <v-card-text v-if="selectedPreviousPlan.length === 0">
                        <v-skeleton-loader
                            type="table-heading, list-item-two-line"
                            >
                        </v-skeleton-loader>
                    </v-card-text>   
                           
                    <v-card-text v-else>
                        <h2>Risk Classification</h2>
                        <p>{{PRMP.RiskClassification.RiskClassification}}</p>
                        <hr>
                        <h2 class="pt-2">Objective</h2>
                        <v-list-item v-for="(objective, index) in this.PRMP.Objectives" :key="index">{{objective}}</v-list-item>
                        <hr>
                        <h2 class="pt-2">Likelihood Level</h2>
                        <p>{{selectedPreviousPlan.LikelihoodLevel.Level}}</p>
                        <hr>
                        <h2 class="pt-2">Impact Level</h2>
                        <p>{{selectedPreviousPlan.ImpactLevel.Level}}</p>
                        <hr>
                        <h2 class="pt-2">Vulnerability</h2>
                        <p>{{selectedPreviousPlan.Vulnerability}}</p>
                        <hr>
                        <h2 class="pt-2">Remarks</h2>
                        <p>{{selectedPreviousPlan.ImpactClassification.name}}</p>
                        <hr>
                        <h2 class="pt-2">Risk Strategy</h2>
                        <p>{{selectedPreviousPlan.RiskStrategy.RiskStrategy}}</p>
                        <div v-if="selectedPreviousPlan.KPI">
                            <hr>
                            <h2 class="pt-2">KPI</h2>
                            <v-list-item v-for="(kpi, index) in selectedPreviousPlan.KPI" :key="index">{{kpi}}</v-list-item>
                        </div>
                        <div v-if="selectedPreviousPlan.TimelineEndDate">
                            <hr>
                            <h2 class="pt-2">Timeline</h2>
                            <p>{{selectedPreviousPlan.TimelineStartDate}} - {{selectedPreviousPlan.TimelineEndDate}}</p>
                        </div>
                        <div v-if="selectedPreviousPlan.Budget">
                            <hr>
                            <h2 class="pt-2">Budget/Resources</h2>
                            <v-list-item v-for="(budget, index) in selectedPreviousPlan.Budget" :key="index">{{budget}}</v-list-item>
                        </div>
                        <v-expansion-panels 
                            class="pa-1"
                            multiple
                            v-model="panel"
                            >
                          <v-expansion-panel>
                                <v-expansion-panel-header>
                                    Causes
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-row>
                                        <v-col>
                                            <v-list v-for="(item, index) in this.ORMP.Causes"
                                                :key="index">
                                                <v-list-item class="">
                                                    {{index+1}} - {{item}}
                                                </v-list-item>
                                            </v-list>
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel>

                            <v-expansion-panel>
                            <v-expansion-panel-header>
                                Impacts
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-row>
                                    <v-col>
                                        <v-list v-for="(item, index) in this.ORMP.Impacts"
                                        :key="index">
                                            <v-list-item class="">
                                                •{{item}}
                                            </v-list-item>
                                         </v-list>
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-content>
                            </v-expansion-panel>

                            <v-expansion-panel v-if="selectedPreviousPlan.RiskManagementLevel !== 'Inherent'">
                            <v-expansion-panel-header>
                                Risk Action
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-row>
                                    <v-col>
                                        <v-col>
                                            <div 
                                            v-for="(riskaction, index) in this.selectedPreviousPlan.RiskActions"
                                            :key="index">
                                                <v-list-item> 
                                                    <v-col>
                                                        <span 
                                                        v-for="(cause, index) in riskaction.causes"
                                                        :key="index">
                                                             {{cause.value+1}}<span v-if="index != (riskaction.causes.length - 1)">,</span></span> 
                                                    </v-col>
                                                    <v-col>{{riskaction.text}}</v-col>
                                                </v-list-item>
                                            </div>
                                        </v-col>
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-card-text>
                </v-card>
                <v-card v-else>

                    
                 <v-card-text v-if="PRMP.length === 0 && PRMPLevelsInherent.length === 0">
                    <v-skeleton-loader
                        type="table-heading, list-item-two-line"
                        >
                    </v-skeleton-loader>
                    </v-card-text>   


                    <v-card-text v-else>
                        <h2>Risk Classification</h2>
                        <p>{{PRMP.RiskClassification.RiskClassification}}</p>
                        <hr>
                        <h2 class="pt-2">Objective</h2>
                        <v-list-item v-for="(objective, index) in this.PRMP.Objectives" :key="index">{{objective}}</v-list-item>
                        <hr>
                        <h2 class="pt-2">Likelihood Level</h2>
                        <p>{{PRMPLevelsInherent[0].LikelihoodLevel.Level}}</p>
                        <hr>
                        <h2 class="pt-2">Impact Level</h2>
                        <p>{{PRMPLevelsInherent[0].ImpactLevel.Level}}</p>
                        <hr>
                        <h2 class="pt-2">Vulnerability</h2>
                        <p>{{PRMPLevelsInherent[0].Vulnerability}}</p>
                        <hr>
                        <h2 class="pt-2">Remarks</h2>
                        <p>{{PRMPLevelsInherent[0].ImpactClassification.name}}</p>
                        <hr>
                        <h2 class="pt-2">Risk Strategy</h2>
                        <p>{{PRMPLevelsInherent[0].RiskStrategy.RiskStrategy}}</p>
                        
                        <v-expansion-panels 
                            class="pa-1"
                            multiple
                            v-model="panel"
                            >  
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    Causes
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-row>
                                        <v-col>
                                            <v-list v-for="(item, index) in this.PRMP.Causes"
                                                :key="index">
                                                <v-list-item class="">
                                                    {{index+1}} - {{item}}
                                                </v-list-item>
                                            </v-list>
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel>

                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    Impact
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-row>
                                        <v-col>
                                            <v-list v-for="(item, index) in this.PRMP.Impacts"
                                                :key="index">
                                                <v-list-item class="">
                                                    •{{item}}
                                                </v-list-item>
                                            </v-list>
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <!-- 
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    Risk Action
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                        <v-row>
                                            <v-col>
                                                <v-col>
                                                    <div 
                                                        v-for="(riskaction, index) in this.ORMPLevelsInherent[0].RiskActions"
                                                        :key="index">
                                                        <v-list-item> 
                                                            <v-col>
                                                                <span 
                                                                v-for="(cause, index) in riskaction.causes"
                                                                :key="index">
                                                                {{cause.value+1}}<span v-if="index != (riskaction.causes.length - 1)">,</span></span> 
                                                            </v-col>
                                                            <v-col>{{riskaction.text}}</v-col>
                                                        </v-list-item>
                                                    </div>
                                                </v-col>
                                            </v-col>
                                        </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel> -->
                        </v-expansion-panels>
                    </v-card-text>  
                </v-card>    
            </v-col>
            <v-col lg="8">
                <RiskManagementStepper :id="this.$route.params.id" @clicked="save"/>
            </v-col> 
        </v-row>
        <v-row v-if="isEdit">
            <v-col v-if="isEdit">
                <EditRiskManagementStepper :editedRiskLevel="editedItem" @clicked="saveEdited" />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import EditRiskManagementStepper from '../components/EditRiskManagementStepper'
import RiskManagementStepper from '../components/RiskManagementStepper'
export default {
components: {
    RiskManagementStepper,
    EditRiskManagementStepper
},
firestore(){
    if(this.$route.path == '/risklevel/'+this.$route.params.id){
        return {
        ORMP: this.$db.collection('ORMP').doc(this.$route.params.id),
        ORMPLevels: this.$db.collection('ORMPLevels').where("ORMPId", "==", this.$route.params.id),
        ORMPLevelsInherent: this.$db.collection('ORMPLevels').where("ORMPId", "==", this.$route.params.id).where("RiskManagementLevel", "==", 'Inherent')
      }
    } else {
        return {
        PRMP: this.$db.collection('PRMP').doc(this.$route.params.id),
        PRMPLevels: this.$db.collection('PRMPLevels').where("PRMPId", "==", this.$route.params.id),
        PRMPLevelsInherent: this.$db.collection('PRMPLevels').where("PRMPId", "==", this.$route.params.id).where("RiskManagementLevel", "==", 'Inherent')
        }
    }
},
// props: ['updated'],
data() {
    return {
        panel: [],
        sortBy: 'CreatedAt',
        sortDesc: true,
        selectedPreviousPlan: '',
        headers: [
            {
                text: 'Status',
                align: 'start',
                sortable: true,
                value: 'RiskManagementLevel',
            },
            { text: 'Date Created', value: 'CreatedAt'},
            { text: 'Created By', value: 'CreatedBy' },
            { text: 'Actions', value: 'actions', sortable: false },
            
 
      ],
        tableValues: [],
        isAddNew: false,
        itemData: [],
        singleSelect: true,
        isEdit: false,
        editedItem: ''
    }
},



methods: {
    returnRMPLevels() {
        if(this.$route.path == '/risklevel/'+this.$route.params.id){
            if(this.ORMPLevels.length == 0){
                return []
            } else {
                return this.ORMPLevels
            }
        } else {
            if(this.PRMPLevels.length == 0){
                return []
            } else {
                return this.PRMPLevels
            }
        }
        
    },
    
     async deleteItem (item) {
        let self = this

            const confirm = await this.$dialog.confirm({
                text: `Do you really want to delete this ORMP Level?`,
                title: `Delete ORMP Level`
            })

            if(confirm){
                 self.$db.collection('ORMPLevels').doc(item['.key']).delete()
                 .then(async()=>{
                    await self.$store.dispatch('logs/createNotifs', {collection:'ORMP',documentKey:item['.key'],module: 'ORMP Levels',action: 'Deleted ORMP Level'}) 
                    self.$dialog.notify.success(`Delete Item from ORMP Levels`, {
                        position: 'bottom-right',
                        timeout: 3000
                    })                     
                 })
            }
      },


    async cancel() {
            const confirm = await this.$dialog.confirm({
                text: `Do you really want to cancel?`,
                title: `Changes won't be saved`
            })

            if(confirm){
                this.isAddNew = false
                this.isEdit = false
                this.editedItem = {}
                window.location.reload()
            }
        
    },
    toggleAdd() {
        this.isAddNew = !this.isAddNew
    },
   async save(value) {
         if(this.$route.path == '/risklevel/'+this.$route.params.id){
               let self = this
             const confirm = await this.$dialog.confirm({
                text: `Do you really want to save this new ORMP Level?`,
                title: `Confirm new ORMP Level`
            })

            if(confirm){
                  self.$db.collection('ORMPLevels').add(value)
                 .then(async(doc)=>{
                     let id = doc.id
                    await self.$store.dispatch('logs/createNotifs', {collection:'ORMPLevels', documentKey:id, module: 'ORMP', action: 'Added New ORMP Level'}) 
                    self.$dialog.notify.success(`Added New Risk`, {
                        position: 'bottom-right',
                        timeout: 3000
                    })                 
                 })
                this.isAddNew = false;

            }
         } else {
               let self = this
             const confirm = await this.$dialog.confirm({
                text: `Do you really want to save this new PRMP Level?`,
                title: `Confirm new PRMP Level`
            })

            if(confirm){
                  self.$db.collection('PRMPLevels').add(value)
                 .then(async(doc)=>{
                     let id = doc.id
                    await self.$store.dispatch('logs/createNotifs', {collection:'PRMPLevels', documentKey:id, module: 'PRMP', action: 'Added New PRMP Level'}) 
                    self.$dialog.notify.success(`Added New Risk`, {
                        position: 'bottom-right',
                        timeout: 3000
                    })                 
                 })
                this.isAddNew = false;

            }
         }
    },

    async saveEdited(value){
        if(this.$route.path == '/risklevel/'+this.$route.params.id){
             let self = this
         let itemKey = this.editedItem.itemKey
             const confirm = await this.$dialog.confirm({
                text: `Do you really want to save your changes this ORMP Level?`,
                title: `Confirm Edited ORMP Level`
            })
             if(confirm){
                  self.$db.collection('ORMPLevels').doc(itemKey).set(value)
                 .then(async()=>{
                    await self.$store.dispatch('logs/createNotifs', {collection:'ORMPLevels', documentKey:itemKey, module: 'ORMP', action: 'Edited ORMP Level'}) 
                    self.$dialog.notify.success(`Edited Risk Level`, {
                        position: 'bottom-right',
                        timeout: 3000
                    })                 
                 }).then(()=>{
                     window.location.reload()
                 })
            }
        } else {
             let self = this
         let itemKey = this.editedItem.itemKey
             const confirm = await this.$dialog.confirm({
                text: `Do you really want to save your changes this PRMP Level?`,
                title: `Confirm Edited PRMP Level`
            })
             if(confirm){
                  self.$db.collection('PRMPLevels').doc(itemKey).set(value)
                 .then(async()=>{
                    await self.$store.dispatch('logs/createNotifs', {collection:'PRMPLevels', documentKey:itemKey, module: 'PRMP', action: 'Edited PRMP Level'}) 
                    self.$dialog.notify.success(`Edited Risk Level`, {
                        position: 'bottom-right',
                        timeout: 3000
                    })                 
                 }).then(()=>{
                     window.location.reload()
                 })
            }
        }
        },

    editRiskLevel(item) {
        this.isEdit = true
        this.editedItem = {
            itemKey: item['.key'],
            CreatedAt: item.CreatedAt,
            CreatedBy: item.CreatedBy,
            Department: item.Department,
            selectedRiskManagementLevel: item.RiskManagementLevel,
            selectedRiskStrategy: item.RiskStrategy,
            selectedRiskTreatmentOwner: item.RiskTreatmentOwner,
            riskActions: item.RiskActions,
            selectedLikelihood: item.LikelihoodLevel,
            selectedImpactLevel: item.ImpactLevel,
            selectedImpactClassification: item.ImpactClassification,
            selectedTimelineStartDate: item.TimelineStartDate,
            selectedTimelineEndDate: item.TimelineEndDate,
            selectedBudget: this.mapInputArr(item.Budget),
            selectedKPI: this.mapInputArr(item.KPI)
        }
    },

    mapInputArr(arr){
        if([arr][0] == 'N/A'){
          return arr
        } else {
            let i
            let mapThis = []
            for(i=0; i < arr.length; i++) {
                mapThis.push({text: arr[i]})
            }
            return mapThis 
        }
    },

    
   
},

computed: {
    returnData(table, field1, field2){
       return this[table][field1][field2]
    },
    returnRiskName(){
        if(this.ORMP.RiskName.RiskName === undefined) {
            return ''
        }
        return this.ORMP.RiskName.RiskName
    },
     mapCauses() {
          if(this.causesInputFields.length === 0){
            return []
          } else {
           let map = this.ORMP.Causes.map(function(currentValue, index){
              return {text:currentValue.text, value: index}
            })
            return map
          }
        }, 
    },

   

}
</script>
<style scoped>
a:link {
  text-decoration: none;
}
</style>