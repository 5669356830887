<template>
  <v-stepper
    v-model="e6"
    vertical
    >
    <v-stepper-step
      :complete="e6 > 1"
      step="1"
      @click="e6 = 1"
      >
      <h4>Risk Strategy</h4>
    </v-stepper-step>
    <v-stepper-content step="1">
      <v-text-field
        disabled
        label="Risk Level"
        :value="editedRiskLevel.selectedRiskManagementLevel"
        >
      </v-text-field>
       <div class="pb-3">
         <v-label>N/A</v-label>
         <v-btn
          v-if="riskManagementLevelSelector() == 'Future'"
          @click="nullField('selectedRiskStrategy')"
          icon
          lass="ml-2"
          >
            <v-icon v-if="editedRiskLevel.selectedRiskStrategy == 'N/A'" style="color: #00C851;">mdi-checkbox-marked-circle</v-icon><v-icon v-else>mdi-close-circle</v-icon>
        </v-btn>
       </div>
      <v-select
        label="Risk Strategy"
        :items="RiskStrategy"
        :disabled="checkNull('selectedRiskStrategy')"
        item-value=".key"
        item-text="RiskStrategy"
        v-model="editedRiskLevel.selectedRiskStrategy"
        outlined
        return-object
        >
      </v-select>
      <v-btn
        color="primary"
        @click="e6++"
      >
        Continue
      </v-btn>
    </v-stepper-content>

    <v-stepper-step
      :complete="e6 > 2"
      step="2"
      @click="e6 = 2"
      >
      <h4>Risk Treatment Owner</h4>
    </v-stepper-step>
    
     <v-stepper-content step="2">
      <div class="pb-3">
        <v-label>N/A</v-label>
        <v-btn
          v-if="riskManagementLevelSelector() == 'Future'"
          @click="nullField('selectedRiskTreatmentOwner')"
          icon
          lass="ml-2"
          >
          <v-icon v-if="editedRiskLevel.selectedRiskTreatmentOwner[0] == 'N/A'" style="color: #00C851;">mdi-checkbox-marked-circle</v-icon><v-icon v-else>mdi-close-circle</v-icon>
        </v-btn>
      </div>
      
      <v-radio-group
        row
        v-model="type"
        >
        <v-radio
          :disabled="checkNull('selectedRiskTreatmentOwner')"
          label="Person"
          value="Person"
          >
        </v-radio>
        <v-radio
          :disabled="checkNull('selectedRiskTreatmentOwner')"
          label="Dept"
          value="Dept"
          >
        </v-radio>
      </v-radio-group>

      <div v-show="type == 'Dept'">
        <v-row>
          <v-col cols="12" sm="2" md="4" v-for="(item) in returnRiskTreatmentOwner" :key="item['.key']">
            <v-checkbox
              :disabled="checkNull('selectedRiskTreatmentOwner')"
              v-model="editedRiskLevel.selectedRiskTreatmentOwner"
              :value="item"
              :label="item.RiskTreatmentOwner"
              > 
            </v-checkbox>
          </v-col>
        </v-row>
      </div>
       
      <div v-show="type == 'Person'">
        <v-row>
          <v-col cols="12" sm="2" md="4" v-for="(item) in returnDepartmentMembers" :key="item['.key']">
            <v-checkbox
              :disabled="checkNull('selectedRiskTreatmentOwner')"
              v-model="editedRiskLevel.selectedRiskTreatmentOwner"
              :value="item"
              :label="item.RiskTreatmentOwner"
              >
            </v-checkbox>
          </v-col>
        </v-row>
      </div>
      <v-btn
        color="primary"
        @click="e6++"
      >
        Continue
      </v-btn>
      <v-btn @click="e6 -= 1">
        Back
      </v-btn>
    </v-stepper-content>

    <v-stepper-step
      :complete="e6 > 3"
      step="3"
      @click="e6 = 3"
      v-if="editedRiskLevel.selectedRiskManagementLevel !== 'Inherent'"
      >
      <h4>Risk Actions</h4>
    </v-stepper-step>

    <v-stepper-content step="3">
      <div>
        <v-label>N/A</v-label>
        <v-btn
          v-if="riskManagementLevelSelector() == 'Future'"
          @click="nullField('riskActions')"
          icon
          class="ml-2"
          ><v-icon v-if="editedRiskLevel.riskActions[0] == 'N/A'" style="color: #00C851;">mdi-checkbox-marked-circle</v-icon><v-icon v-else>mdi-close-circle</v-icon>
        </v-btn>
      </div>
      <v-btn
        @click="addNewRiskAction"
        :disabled="checkNull('riskActions')"
        class="float-right ml-3 add-button-position mt-3"
        fab
        small
        color="primary"
        >
        <v-icon dark>
          mdi-plus
        </v-icon>
      </v-btn>
      <div 
        v-for="(field, index) in this.editedRiskLevel.riskActions" 
        :key="index" 
        class="mb-4"
        >
        <v-combobox
          :search-input.sync="search"
          @input="search = ''"
          :disabled="checkNull('riskActions')"
          v-model="field.causes"
          class="mt-3"
          :items="mapCauses"
          item-text="text"
          item-value="value"
          chips
          clearable
          label="Select Causes"
          multiple
          prepend-icon="mdi-filter-variant"
          solo
          >
          <template v-slot:selection="{ attrs, item, select, selected }">
            <v-chip
              v-bind="attrs"
              :input-value="selected"
              close
              @click="select"
              @click:close="remove(item,index)"
              >
              <strong>{{ item.text }}</strong>&nbsp;
            </v-chip>
          </template>
        </v-combobox>
        <v-text-field 
          :disabled="checkNull('riskActions')"
          :placeholder="index !== 0 ? 'Input another Risk Action' : 'Input a Risk Action'"
          v-model="field.text"
          :append-icon="index !== 0 ? 'mdi mdi-delete' : ''"
          @click:append="deleteObjectiveInput(index, 'riskActions')"
          >
        </v-text-field>
      </div>
      <v-btn
        color="primary"
        @click="e6++"
        >
        Continue
      </v-btn>
      <v-btn @click="e6 -= 1">
        Back
      </v-btn>
    </v-stepper-content>

    <v-stepper-step
      v-if="editedRiskLevel.selectedRiskManagementLevel !== 'Inherent'"
      :complete="e6 > 4"
      step="4"
      @click="e6 = 4"
      >
      <h4>{{editedRiskLevel.selectedRiskManagementLevel}} Risk Rating</h4>
    </v-stepper-step>
    <v-stepper-step
      v-else
      :complete="e6 > 4"
      step="2"
      @click="e6 = 4"
      >
      <h4>{{editedRiskLevel.selectedRiskManagementLevel}} Risk Rating</h4>
    </v-stepper-step>

    <v-stepper-content step="4" v-if="editedRiskLevel.selectedRiskManagementLevel !== 'Inherent'">
      <div>
        <v-label>N/A</v-label>
        <v-btn
          v-if="riskManagementLevelSelector() == 'Future'"
          @click="nullField('selectedLikelihood'); nullField('selectedImpactClassification'); nullField('selectedImpactLevel');"
          icon
          class="ml-2"
          ><v-icon v-if="editedRiskLevel.selectedLikelihood == 'N/A'" style="color: #00C851;">mdi-checkbox-marked-circle</v-icon><v-icon v-else>mdi-close-circle</v-icon>
        </v-btn>
      </div>
      <v-row>
        <v-col v-if="riskManagementLevelSelector() == 'Residual'">
          <v-select
            :disabled="checkNull('selectedLikelihood')"
            :items="LikelihoodLevel"
            item-text="Level"
            item-value="Level"
            return-object
            :rules="returnRules('returnInherentLikelihood', 'Inherent Level')"
            label="Select Likelihood Level"
            outlined
            v-model="editedRiskLevel.selectedLikelihood"
            >
          </v-select>
        </v-col>

        <v-col v-else-if="riskManagementLevelSelector() == 'Future'">
          <v-select
            :disabled="checkNull('selectedLikelihood')"
            :items="LikelihoodLevel"
            item-text="Level"
            item-value="Level"
            return-object
            :rules="returnRules('returnResidualLikelihood', 'Residual Level')"
            label="Select Likelihood Level"
            outlined
            v-model="editedRiskLevel.selectedLikelihood"
            >
          </v-select> 
        </v-col>
      </v-row>
      <v-row v-if="this.editedRiskLevel.selectedLikelihood !== 'N/A'">
        <v-col cols="6" v-if="this.editedRiskLevel.selectedLikelihood && this.editedRiskLevel.selectedLikelihood !== 'N/A'">
          <v-list v-if="this.editedRiskLevel.selectedLikelihood">
            <v-list-item><h3>Frequency</h3></v-list-item>
              <v-list-item>
                {{editedRiskLevel.selectedLikelihood.Frequency}}
              </v-list-item>
            </v-list>  
        </v-col>
        <v-col cols="6" v-if="this.editedRiskLevel.selectedLikelihood && this.editedRiskLevel.selectedLikelihood !== 'N/A'">
          <v-list v-if="this.editedRiskLevel.selectedLikelihood">
            <v-list-item><h3>Probability</h3></v-list-item>
              <v-list-item>
                {{editedRiskLevel.selectedLikelihood.Probability}}
              </v-list-item>
          </v-list>  
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-col v-if="riskManagementLevelSelector() == 'Residual'">
            <v-select
              :disabled="checkNull('selectedLikelihood')"
              :items="RiskImpactLevel"
              item-text="Level"
              item-value=".key"
              :rules="returnRules('returnInherentImpact', 'Inherent Level')"
              return-object
              label="Select Impact Criteria Level"
              outlined
              v-model="editedRiskLevel.selectedImpactLevel"
              >
            </v-select>
          </v-col>
          <v-col v-else-if="riskManagementLevelSelector() == 'Future'">
            <v-select
              :disabled="checkNull('selectedLikelihood')"
              :items="RiskImpactLevel"
              item-text="Level"
              item-value=".key"
              :rules="returnRules('returnResidualImpact', 'Residual Level')"
              return-object
              label="Select Impact Criteria Level"
              outlined
              v-model="editedRiskLevel.selectedImpactLevel"
              >
            </v-select>
          </v-col>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-select
            :disabled="checkNull('selectedLikelihood')"
            :items="ImpactArr"
            item-text="name"
            item-value="id"
            label="Select Impact Classification"
            return-object
            outlined
            v-model="editedRiskLevel.selectedImpactClassification"
            >
          </v-select>
        </v-col>
      </v-row>
          
      <v-row v-if="this.editedRiskLevel.selectedLikelihood !== 'N/A'"> 
        <v-col>
          <v-list v-if="this.editedRiskLevel.selectedLikelihood && this.editedRiskLevel.selectedImpactLevel && this.editedRiskLevel.selectedImpactClassification">
            <v-list-item><h3>Description</h3></v-list-item>
              <v-list-item 
                v-for="(impact, index) in this.displayImpact"
                :key="index" 
                >{{impact}}</v-list-item>
            </v-list>                
        </v-col>
        <v-col>
          <v-list v-if="this.editedRiskLevel.selectedLikelihood && this.editedRiskLevel.selectedImpactLevel && this.editedRiskLevel.selectedImpactClassification">
            <v-list-item><h3>Vulnerability Rating</h3></v-list-item>
            <v-list-item>
              {{computeVulnerability}}
            </v-list-item>
          </v-list> 
        </v-col>
      </v-row>
      <v-btn
        color="primary"
        @click="e6 += 1"
        >
        Continue
      </v-btn>
      <v-btn @click="e6 -= 1">
        Back
      </v-btn>
    </v-stepper-content>
    
    <v-stepper-content step="2" v-else>
      <v-row>
        <v-col>
          <v-select
            :items="LikelihoodLevel"
            item-text="Level"
            item-value=".key"
            return-object
            label="Select Likelihood Level"
            outlined
            v-model="editedRiskLevel.selectedLikelihood"
            >
          </v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6" v-if="this.editedRiskLevel.selectedLikelihood">
          <v-list v-if="this.editedRiskLevel.selectedLikelihood">
            <v-list-item><h3>Frequency</h3></v-list-item>
            <v-list-item>
              {{editedRiskLevel.selectedLikelihood.Frequency}}
            </v-list-item>
          </v-list>  
        </v-col>
        <v-col cols="6" v-if="this.editedRiskLevel.selectedLikelihood">
          <v-list v-if="this.editedRiskLevel.selectedLikelihood">
            <v-list-item><h3>Probability</h3></v-list-item>
            <v-list-item>
              {{editedRiskLevel.selectedLikelihood.Probability}}
            </v-list-item>
          </v-list>  
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-select
            :items="RiskImpactLevel"
            item-text="Level"
            item-value=".key"
            return-object
            label="Select Impact Criteria Level"
            outlined
            v-model="editedRiskLevel.selectedImpactLevel"
            >
          </v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-select
            :items="ImpactArr"
            item-text="name"
            item-value="id"
            label="Select Impact Classification"
            return-object
            outlined
            v-model="editedRiskLevel.selectedImpactClassification"
            >
          </v-select>
        </v-col>
      </v-row>
          
      <v-row>
        <v-col>
          <v-list v-if="this.editedRiskLevel.selectedLikelihood && this.editedRiskLevel.selectedImpactLevel && this.editedRiskLevel.selectedImpactClassification">
            <v-list-item><h3>Description</h3></v-list-item>
            <v-list-item 
              v-for="(impact, index) in this.displayImpact"
              :key="index" 
              >{{impact}}</v-list-item>
            </v-list>              
              
        </v-col>
        <v-col>
          <v-list v-if="this.editedRiskLevel.selectedLikelihood && this.editedRiskLevel.selectedImpactLevel && this.editedRiskLevel.selectedImpactClassification">
            <v-list-item><h3>Vulnerability Rating</h3></v-list-item>
              <v-list-item>
                {{computeVulnerability}}
            </v-list-item>
          </v-list> 
        </v-col>
      </v-row>
      <v-btn
        color="primary"
        @click="saveEdited()"
        >
        Save
      </v-btn>
      <v-btn @click="e6 -= 1">
        Back
      </v-btn>
    </v-stepper-content>
    <v-stepper-step
      :complete="e6 > 5"
      step="5"
      @click="e6 = 5"
      >
      <h4>KPI</h4>
    </v-stepper-step>
    <v-stepper-content step="5">
      <div>
        <v-label>N/A</v-label>
        <v-btn
          v-if="riskManagementLevelSelector() == 'Future'"
          @click="nullField('selectedKPI');"
          icon
          class="ml-2"
          ><v-icon v-if="editedRiskLevel.selectedKPI[0] == 'N/A'" style="color: #00C851;">mdi-checkbox-marked-circle</v-icon><v-icon v-else>mdi-close-circle</v-icon>
        </v-btn>
      </div>
      <v-btn
        @click="addNewField('selectedKPI')"
        class="float-right mr-1 add-button-position"
        fab
        small
        color="primary"
        :disabled="checkNull('selectedKPI')"
        >
        <v-icon dark>
          mdi-plus
        </v-icon>
      </v-btn>
      <v-text-field 
        :disabled="checkNull('selectedKPI')"
        v-for="(field, index) in editedRiskLevel.selectedKPI" 
        :key="index" 
        :placeholder="index !== 0 ? 'Input another KPI' : 'Input a KPI'"
        v-model="field.text"
        :append-icon="index !== 0 ? 'mdi mdi-delete' : ''"
        @click:append="deleteObjectiveInput(index, 'selectedKPI')"
        >
      </v-text-field>
      <v-btn
        color="primary"
        @click="e6++"
        >
        Continue
      </v-btn>
      <v-btn @click="e6 -= 1">
        Back
      </v-btn>
    </v-stepper-content>

    <v-stepper-step
      :complete="e6 > 6"
      step="6"
      @click="e6 = 6"
      >
      <h4>Timeline</h4>
    </v-stepper-step>
    <v-stepper-content step="6">
      <div>
        <v-label>N/A</v-label>
        <v-btn
          v-if="riskManagementLevelSelector() == 'Future'"
          @click="nullField('selectedTimelineStartDate'); nullField('selectedTimelineEndDate')"
          icon
          class="ml-2"
          ><v-icon v-if="editedRiskLevel.selectedTimelineStartDate == 'N/A'" style="color: #00C851;">mdi-checkbox-marked-circle</v-icon><v-icon v-else>mdi-close-circle</v-icon>
        </v-btn>
      </div>
      <v-row class="pl-4">
        <v-menu
          ref="menu1"
          :disabled="checkNull('selectedTimelineStartDate')"
          v-model="menu1"
          :close-on-content-click="false"
          :return-value.sync="editedRiskLevel.selectedTimelineStartDate"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
          >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="editedRiskLevel.selectedTimelineStartDate"
              label="Start Date"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              >
            </v-text-field>
          </template>
          <v-date-picker
            type="month"
            v-model="editedRiskLevel.selectedTimelineStartDate"
            no-title
            scrollable
            :min="getMin"
            >
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="menu1 = false"
              >
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.menu1.save(editedRiskLevel.selectedTimelineStartDate)"
              >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-row>

      <v-row class="pl-4">
        <v-menu
          ref="menu2"
          v-model="menu2"
          :disabled="checkNull('selectedTimelineEndDate')"
          :close-on-content-click="false"
          :return-value.sync="editedRiskLevel.selectedTimelineEndDate"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
          >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="editedRiskLevel.selectedTimelineEndDate"
              label="End Date"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              >
            </v-text-field>
          </template>
          <v-date-picker
            type="month"
            v-model="editedRiskLevel.selectedTimelineEndDate"
            no-title
            scrollable
            :min="getMin"
            >
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="menu2 = false"
              >
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.menu2.save(editedRiskLevel.selectedTimelineEndDate)"
              >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-row>

      <v-row class="pt-4 pl-4">
        <v-btn
          color="primary"
          @click="e6 += 1"
          >
          Continue
        </v-btn>
        <v-btn @click="e6 -= 1">
          Back
        </v-btn>
      </v-row>
    </v-stepper-content>
    
    <v-stepper-step
      :complete="e6 > 7"
      step="7"
      @click="e6 = 7"
        >
        <h4>Budget/Resources</h4>
    </v-stepper-step>
    <v-stepper-content step="7">
      <div>
        <v-label>N/A</v-label>
        <v-btn
          v-if="riskManagementLevelSelector() == 'Future'"
          @click="nullField('selectedBudget');"
          icon
          class="ml-2"
          ><v-icon v-if="editedRiskLevel.selectedBudget[0] == 'N/A'" style="color: #00C851;">mdi-checkbox-marked-circle</v-icon><v-icon v-else>mdi-close-circle</v-icon>
        </v-btn>
      </div>
      <v-btn
        @click="addNewField('selectedBudget')"
        class="float-right mr-1 add-button-position"
        fab
        small
        color="primary"
        :disabled="checkNull('selectedBudget')"
        >
        <v-icon dark>
          mdi-plus
        </v-icon>
      </v-btn>
      <v-text-field 
        :disabled="checkNull('selectedBudget')"
        v-for="(field, index) in editedRiskLevel.selectedBudget" 
        :key="index" 
        :placeholder="index !== 0 ? 'Input another Budget' : 'Input a Budget'"
        v-model="field.text"
        :append-icon="index !== 0 ? 'mdi mdi-delete' : ''"
        @click:append="deleteObjectiveInput(index, 'selectedBudget')"
        >
      </v-text-field>
      <v-btn
        color="primary"
        @click="saveEdited()"
        >
        Save
      </v-btn>
      <v-btn @click="e6 -= 1">
        Back
      </v-btn>
    </v-stepper-content>
  </v-stepper>
</template>

<script>
export default {
  props: ['editedRiskLevel'],
  firestore(){
    if(this.$route.path == '/risklevel/'+this.$route.params.id){
      return {
        ORMP: this.$db.collection('ORMP').doc(this.$route.params.id),
        ORMPLevels: this.$db.collection('ORMPLevels').where("ORMPId", "==", this.$route.params.id),
        ORMPLevelsInherent: this.$db.collection('ORMPLevels').where("ORMPId", "==", this.$route.params.id).where("RiskManagementLevel", "==", 'Inherent'),
        RiskStrategy: this.$db.collection('RiskStrategy'),
        RiskTreatmentOwner: this.$db.collection('RiskTreatmentOwner'),
        LikelihoodLevel: this.$db.collection('LikelihoodLevel').orderBy("Level", "desc"),
        RiskImpactLevel: this.$db.collection('RiskImpactLevel'),
        UserAccounts: this.$db.collection('UserAccounts'),
      }
    } else {
      return {
        PRMP: this.$db.collection('PRMP').doc(this.$route.params.id),
        PRMPLevels: this.$db.collection('PRMPLevels').where("PRMPId", "==", this.$route.params.id),
        PRMPLevelsInherent: this.$db.collection('PRMPLevels').where("PRMPId", "==", this.$route.params.id).where("RiskManagementLevel", "==", 'Inherent'),
        RiskStrategy: this.$db.collection('RiskStrategy'),
        RiskTreatmentOwner: this.$db.collection('RiskTreatmentOwner'),
        LikelihoodLevel: this.$db.collection('LikelihoodLevel').orderBy("Level", "desc"),
        RiskImpactLevel: this.$db.collection('RiskImpactLevel'),
        UserAccounts: this.$db.collection('UserAccounts'),
      }
    }
  },
  data(){
    return {
      search: '',
      type: 'Person',
      menu1: false,
      menu2: false,
      e6: 1,
      riskManagamentLevel: [{id: 1, Level: 'Residual'}, {id: 2, Level: 'Target'}, {id: 3, Level: 'Inherent'}],
      selectedRiskManagementLevel: '',
      selectedRiskStrategy: '',
      riskActions: [
        {
          text: '',
          causes: []
        }
      ],
      selectedLikelihood: '',
      selectedImpactLevel: '',
      selectedImpactClassification: '',
      selectedriskTreatmentOwners: [
        {
          text: '',
          type: 'Person'
        }
      ],
      ImpactArr: [
        {id: 'Environment', name: 'Environment'},
        {id: 'HSS', name: 'Health, Safety & Security'},
        {id: 'LegalCompliance', name: 'Legal and Compliance'},
        {id: 'NIAT', name: 'Net Income After Tax'},
        {id: 'MGTACTION', name: 'Mgt Action'},
        {id: 'Operational', name: 'Operational'},
        {id: 'Reputation', name: 'Reputation'},
      ],
    }
  },
    
  methods: {
    checkNull(data){
      if(data == 'selectedRiskStrategy' || data == 'kpiInputFields' || data == 'budgetInputFields' || data == 'selectedTimelineStartDate' || data == 'selectedTimelineEndDate' || data == 'selectedLikelihood' || data == 'selectedImpactClassification' || data == 'selectedImpactLevel'){
        if(this.editedRiskLevel[data] == 'N/A'){
          return true
        } else {
          return false
        }
      } else {
        if(this.editedRiskLevel[data][0] == 'N/A'){
          return true
        } else {
          return false
        }
      }
    },

    nullField(data){
      if(data == 'selectedRiskTreatmentOwner'){
        if(this.editedRiskLevel[data] == 'N/A'){
          this.editedRiskLevel[data] = []
        } else {
          this.editedRiskLevel[data] = ['N/A']
        }
      } else if(data == 'riskActions' || data == 'selectedKPI' || data == 'selectedBudget'){
        if(this.editedRiskLevel[data] == 'N/A'){
          this.editedRiskLevel[data] = [
            {
              text: ''
            }
          ] 
        } else {
          this.editedRiskLevel[data] = ["N/A"]
        }
      } else {
        if(this.editedRiskLevel[data] == 'N/A'){
          this.editedRiskLevel[data] = ''
        } else {
          this.editedRiskLevel[data] = 'N/A'
        }
      }
    },

    mapInputArr(){
      if(this.$route.path == '/risklevel/'+this.$route.params.id){
        if(this.ORMP.length === 0){
          return []
        } else {
          let i
          let arr = this.ORMP.Causes
          let mapThis = []
          for(i=0; i < arr.length; i++) {
           mapThis.push({text: arr[i]})
          }
          return mapThis 
        }
      } else {
        if(this.PRMP.length === 0){
          return []
        }
        let i
        let arr = this.PRMP.Causes
        let mapThis = []
        for(i=0; i < arr.length; i++) {
          mapThis.push({text: arr[i]})
        }
          return mapThis 
        }
      },

    mapInput(arr){
      if(this.editedRiskLevel[arr] == 'N/A'){
        return this.editedRiskLevel[arr]
      } else {
          let values = this.editedRiskLevel[arr].map(function(value) {
            return value['text']
          });
          return values;
        }
    },
    returnInherentLikelihood(){
  
         let filter = this.ORMPLevels.filter(e=>{
            return e.RiskManagementLevel == 'Inherent'
          })
     
          return filter[0].LikelihoodLevel.Level
        },
        returnResidualLikelihood(){
          
  
         let filter = this.ORMPLevels.filter(e=>{
            return e.RiskManagementLevel == 'Residual'
          })
          if(filter){
             return filter[0].LikelihoodLevel.Level
          }
     
         
        },
        returnInherentImpact(){
           let filter = this.ORMPLevels.filter(e=>{
            return e.RiskManagementLevel == 'Inherent'
          })
     
          return filter[0].ImpactLevel.Level
        },
        returnResidualImpact(){
           let filter = this.ORMPLevels.filter(e=>{
            return e.RiskManagementLevel == 'Residual'
          })
     
          return filter[0].ImpactLevel.Level
        },
       riskManagementLevelSelector() {
         if(this.$route.path == '/risklevel/'+this.$route.params.id){
              let result;
              if(this.ORMPLevels.length == 0){
                  return ''
              }  
              let mapped = this.ORMPLevels.map(e => {
                return e.RiskManagementLevel
              })
              if(mapped.length >= 2){
                  result =  "Future"
              } else {
                  result = "Residual"
            }
            return result

          } else {
            let result;
            if(this.PRMPLevels.length == 0){
                return ''
            }  
            let mapped = this.PRMPLevels.map(e => {
              return e.RiskManagementLevel
            })
            if(mapped.length >= 2){
                result =  "Future"
            } else {
                result = "Residual"
            }
              return result
            }
        },
        returnRules(func, level){
          return [value => (value.Level <= this[func]()) || 'Cannot be higher than previous ' + level]
        },
        mapMultiRTO(){
          let mapped = []
          this.editedRiskLevel.selectedRiskTreatmentOwner.forEach(e1=>{
            mapped.push({
             RiskTreatmentOwner: e1.RiskTreatmentOwner,
              type: e1.type
             })
        })
        return mapped   
      },
        addNewFieldRTO() {
         this.editedRiskLevel.selectedRiskTreatmentOwner.push({text: '', type: 'Person'})
        },
       formatName(user){
          let email = user.emailAdd
          let splitLast = email.split('@')
          let name = splitLast[0]
          let splitName = name.split('.')
          let firstname = splitName[0].charAt(0).toUpperCase() + splitName[0].slice(1)
          let lastname = splitName[1].charAt(0).toUpperCase() + splitName[1].slice(1)
          let fullname = firstname + ' ' + lastname
         return {['.key']: user['.key'], RiskTreatmentOwner: fullname}
        },
        saveEdited() {
            if(this.$route.path == '/risklevel/'+this.$route.params.id){
            let data = {
            ORMPId: this.$route.params.id,
            CoverageId: this.ORMP.CoverageId,
            RiskStrategy: this.editedRiskLevel.selectedRiskStrategy,
            CreatedAt: this.editedRiskLevel.CreatedAt,
            CreatedBy: this.editedRiskLevel.CreatedBy,
            Department: this.editedRiskLevel.Department,
            Modified: this.$firebase.firestore.FieldValue.serverTimestamp(),
            ModifiedBy: this.$store.getters['useraccount/isAuthenticated'].email,
            RiskManagementLevel: this.editedRiskLevel.selectedRiskManagementLevel,
            RiskActions: this.editedRiskLevel.riskActions,
            LikelihoodLevel: this.editedRiskLevel.selectedLikelihood,
            ImpactLevel: this.mapImpact(),
            ImpactClassification: this.editedRiskLevel.selectedImpactClassification,
            RiskTreatmentOwner: this.editedRiskLevel.selectedRiskTreatmentOwner,
            Vulnerability: this.computeVulnerabilityMethod(),
            TimelineEndDate: this.editedRiskLevel.selectedTimelineEndDate,
            TimelineStartDate: this.editedRiskLevel.selectedTimelineStartDate,
            Budget: this.mapInput('selectedBudget'),
            KPI: this.mapInput('selectedKPI')
          }
          this.$emit('clicked', data)
          } else {
            let data = {
            PRMPId: this.$route.params.id,
            CoverageId: this.PRMP.CoverageId,
            RiskStrategy: this.editedRiskLevel.selectedRiskStrategy,
            CreatedAt: this.editedRiskLevel.CreatedAt,
            CreatedBy: this.editedRiskLevel.CreatedBy,
            Department: this.editedRiskLevel.Department,
            Modified: this.$firebase.firestore.FieldValue.serverTimestamp(),
            ModifiedBy: this.$store.getters['useraccount/isAuthenticated'].email,
            RiskManagementLevel: this.editedRiskLevel.selectedRiskManagementLevel,
            RiskActions: this.editedRiskLevel.riskActions,
            LikelihoodLevel: this.editedRiskLevel.selectedLikelihood,
            ImpactLevel: this.mapImpact(),
            ImpactClassification: this.editedRiskLevel.selectedImpactClassification,
            RiskTreatmentOwner: this.editedRiskLevel.selectedRiskTreatmentOwner,
            Vulnerability: this.computeVulnerabilityMethod(),
            TimelineEndDate: this.editedRiskLevel.selectedTimelineEndDate,
            TimelineStartDate: this.editedRiskLevel.selectedTimelineStartDate,
            Budget: this.mapInput('selectedBudget'),
            KPI: this.mapInput('selectedKPI')
          }
          this.$emit('clicked', data)
          }
        },

        saveEditedInherent(){
            let data = {
            ORMPId: this.$route.params.id,
            CoverageId: this.ORMP.CoverageId,
            RiskStrategy: this.editedRiskLevel.selectedRiskStrategy,
            CreatedAt: this.editedRiskLevel.CreatedAt,
            CreatedBy: this.editedRiskLevel.CreatedBy,
            Department: this.editedRiskLevel.Department,
            Modified: this.$firebase.firestore.FieldValue.serverTimestamp(),
            ModifiedBy: this.$store.getters['useraccount/isAuthenticated'].email,
            RiskManagementLevel: this.editedRiskLevel.selectedRiskManagementLevel,
            LikelihoodLevel: this.editedRiskLevel.selectedLikelihood,
            ImpactLevel: this.mapImpact(),
            ImpactClassification: this.editedRiskLevel.selectedImpactClassification,
            RiskTreatmentOwner: this.mapMulti('selectedRiskTreatmentOwner', 'RiskTreatmentOwner'),
            Vulnerability: this.computeVulnerabilityMethod()
          }
            this.$emit('clicked', data)
            console.log(data)
        },

        computeVulnerabilityMethod(){
          if(this.editedRiskLevel.selectedLikelihood == 'N/A' && this.editedRiskLevel.selectedImpactLevel == 'N/A'){
            return 'N/A'
          } else {
            return this.editedRiskLevel.selectedLikelihood.Level * this.editedRiskLevel.selectedImpactLevel.Level
          }
        },

        mapImpact() {
            let arr = this.RiskImpactLevel
          if(this.editedRiskLevel.selectedImpactLevel === ''){
              return ''
            } else if (this.editedRiskLevel.selectedImpactLevel == 'N/A'){
              return this.editedRiskLevel.selectedImpactLevel
            } else {
              let filter = arr.filter(e => {
              return e['.key'] == this.editedRiskLevel.selectedImpactLevel['.key']  
            }) 
              if (this.editedRiskLevel.selectedImpactClassification === '') {
                return []
              } else {
                return {
                  ['.key']:this.editedRiskLevel.selectedImpactLevel['.key'], 
                  Level:filter[0].Level, 
               }}
              }
        },

        mapMulti(arr, fieldName){
          let mapped = []
          this.editedRiskLevel[arr].forEach(e1=>{
            mapped.push({
             ['.key']: e1.text['.key'], 
             [fieldName]:e1.text[fieldName]
             })
          })
            return mapped   
        },
        remove (item,index) {
            this.editedRiskLevel.riskActions[index].causes.splice(this.editedRiskLevel.riskActions[index].causes.indexOf(item), 1)
        },
        addNewRiskAction() {
            this.editedRiskLevel.riskActions.push({text: ''})
        },
        deleteObjectiveInput(index, arrayName) {
            this.editedRiskLevel[arrayName].splice(index, 1)
        },
        addNewField(arrayName) {
          this.editedRiskLevel[arrayName].push({text: ''})
        }
    },
    computed: {
           getMin() {
            // var d = new Date(new Date().getFullYear() + 1, 0, 2);
            var d = new Date();
            return d.toISOString().substring(0, 7);
        },
           returnRiskTreatmentOwner(){
        if(this.RiskTreatmentOwner.length == 0){
          return []
        } 
        let arr = this.RiskTreatmentOwner
        let newArr = []
        arr.forEach(e=>{
          let x = {...e}
          x.Type = 'Department'
          newArr.push(x)
        })
        return newArr
      },  
       returnDepartmentMembers(){
        let arr = []
       if(this.UserAccounts.length == 0){
         return []
       }
       let filtered = this.UserAccounts.filter(e=>{
         return e.department == this.$store.getters['useraccount/isAuthenticated'].department
       })

       filtered.forEach(e=>{
         arr.push(this.formatName(e))
       })
      //  console.log(arr)
      //  console.log(this.RiskTreatmentOwner)
       return arr
      },
        computeVulnerability() {
          return this.editedRiskLevel.selectedLikelihood.Level * this.editedRiskLevel.selectedImpactLevel.Level
        },

        displayImpact() {
        if(this.RiskImpactLevel.length == 0){
             return []
        } else if (this.editedRiskLevel.selectedImpactClassification == 'N/A'){
          return this.editedRiskLevel.selectedImpactClassification
        }
          let id = this.editedRiskLevel.selectedImpactClassification.id
          let arr = this.RiskImpactLevel
            if(this.editedRiskLevel.selectedImpactLevel === ''){
              return []
            } else {
              let filter = arr.filter(e => {
              return e['.key'] == this.editedRiskLevel.selectedImpactLevel['.key']
            })      
              return filter[0][id]    
            }
        },

        mapCauses() {
            if(this.mapInputArr().length == 0){
                return []
            }
           let map = this.mapInputArr().map(function(currentValue, index){
              return {text:currentValue.text, value: index}
            })
            return map
        },
        check() {
          return this.mapCauses
        }
    }
}
</script>

<style scoped>
h4:hover{
  cursor: pointer;
  color: gray;
}
h4{
  font-weight: normal;
}
</style>