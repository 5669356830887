<template>
  <v-stepper
    v-model="e6"
    vertical
    >
    <v-stepper-step
      :complete="e6 > 1"
      step="1"
      @click="e6 = 1"
      >
      <h4>Risk Strategy</h4>
    </v-stepper-step>

    <v-stepper-content step="1">
      <v-text-field
        disabled
        label="Risk Level"
        :value="riskManagementLevelSelector()"
        >
      </v-text-field>
      <div v-if="riskManagementLevelSelector() == 'Future'">
        <v-label>N/A</v-label>
        <v-btn
          v-if="riskManagementLevelSelector() == 'Future'"
          @click="nullField('selectedRiskStrategy')"
          icon
          class="ml-2"
          ><v-icon v-if="selectedRiskStrategy == 'N/A'" style="color: #00C851;">mdi-checkbox-marked-circle</v-icon><v-icon v-else>mdi-close-circle</v-icon>
        </v-btn>
      </div>
      <v-select
        class="pt-3"
        label="Risk Strategy"
        :items="RiskStrategy"
        :disabled="checkNull('selectedRiskStrategy')"
        item-value=".key"
        item-text="RiskStrategy"
        v-model="selectedRiskStrategy"
        outlined
        return-object
        >
      </v-select>
      <v-btn
        color="primary"
        @click="e6++"
        >
        Continue
      </v-btn>    
    </v-stepper-content>

    <v-stepper-step
      :complete="e6 > 2"
      step="2"
      @click="e6 = 2"
      >
      <h4>Risk Treatment Owner</h4>
    </v-stepper-step>

    <v-stepper-content step="2">
      <!-- <v-radio-group
              row
              v-model="type"
              >
              <v-radio
                label="Person"
                value="Person"
                >
              </v-radio>
              <v-radio
                label="Dept"
                value="Dept"
                >
              </v-radio>
           </v-radio-group>

          <div v-show="type == 'Dept'">
            <v-row>
              <v-col cols="12" sm="2" md="4" v-for="(item) in returnRiskTreatmentOwner" :key="item['.key']">
                <v-checkbox
                  v-model="edited.selectedriskTreatmentOwners"
                  :value="item"
                  :label="item.RiskTreatmentOwner"
                  >
                </v-checkbox>
              </v-col>
            </v-row>
          </div>
  
          <div v-show="type == 'Person'">
            <v-row>
              <v-col cols="12" sm="2" md="4" v-for="(item) in returnDepartmentMembers" :key="item['.key']">
                <v-checkbox
                  v-model="edited.selectedriskTreatmentOwners"
                  :value="item"
                  :label="item.RiskTreatmentOwner"
                  >
                </v-checkbox>
              </v-col>
            </v-row>
          </div> -->
       
          <!-- 
          <v-btn
            @click="addNewFieldRTO()"
            class="float-right ml-3 mt-2 add-button-position"
            fab
            small
            color="primary"
            >
            <v-icon dark>
              mdi-plus
            </v-icon>
          </v-btn>

          <div v-for="(field, index) in selectedriskTreatmentOwners" :key="index">
            <v-btn
              @click="deleteObjectiveInput(index, 'selectedriskTreatmentOwners')"
              v-if="index !== 0 ? 'mdi mdi-delete' : ''"
              class="float-right ml-3 mt-2 add-button-position"
              fab
              small
              color="primary"
              >
              <v-icon dark>
                mdi-delete
              </v-icon>
            </v-btn>

            <v-radio-group
              row
              v-model="field.type"
              >
              <v-radio
                label="Person"
                value="Person"
                >
              </v-radio>
              <v-radio
                label="Dept"
                value="Dept"
                >
              </v-radio>
            </v-radio-group>

            <v-select
              v-if="field.type == 'Dept'"
              :items="RiskTreatmentOwner"
              item-text="RiskTreatmentOwner"
              item-value="RiskTreatmentOwner"
              return-object
              label="Select Risk Treatment Owner"
              outlined
              v-model="field.text"
              >
            </v-select>

            <v-select
              v-else
              :items="returnDepartmentMembers"
              item-text="RiskTreatmentOwner"
              return-object
              label="Select Risk Treatment Owner"
              outlined
              v-model="field.text"
              >
            </v-select> -->
          <!-- </div> -->
      <div v-if="riskManagementLevelSelector() == 'Future'">
        <v-label>N/A</v-label>
        <v-btn
          v-if="riskManagementLevelSelector() == 'Future'"
          @click="nullField('selectedriskTreatmentOwners')"
          icon
          class="ml-2"
          ><v-icon v-if="selectedriskTreatmentOwners[0] == 'N/A'" style="color: #00C851;">mdi-checkbox-marked-circle</v-icon><v-icon v-else>mdi-close-circle</v-icon>
        </v-btn>
      </div>
      <v-radio-group
        row
        v-model="type"
        >
          <v-radio
            :disabled="checkNull('selectedriskTreatmentOwners')"
            label="Person"
            value="Person"
            >
          </v-radio>
          <v-radio
            :disabled="checkNull('selectedriskTreatmentOwners')"
            label="Dept"
            value="Dept"
            >
          </v-radio>
      </v-radio-group>

      <div v-show="type == 'Dept'">
        <v-row>
          <v-col cols="12" sm="2" md="4" v-for="(item) in returnRiskTreatmentOwner" :key="item['.key']">
            <v-checkbox
              :disabled="checkNull('selectedriskTreatmentOwners')"
              v-model="selectedriskTreatmentOwners"
              :value="item"
              :label="item.RiskTreatmentOwner"
              > 
            </v-checkbox>
          </v-col>
        </v-row>
      </div>
       
      <div v-show="type == 'Person'">
        <v-row>
          <v-col cols="12" sm="2" md="4" v-for="(item) in returnDepartmentMembers" :key="item['.key']">
            <v-checkbox
              :disabled="checkNull('selectedriskTreatmentOwners')"
              v-model="selectedriskTreatmentOwners"
              :value="item"
              :label="item.RiskTreatmentOwner"
              >
            </v-checkbox>
          </v-col>
        </v-row>
      </div>
       
      <v-btn
        color="primary"
        @click="e6++"
        >
        Continue
      </v-btn>
      <v-btn @click="e6 -= 1">
        Back
       </v-btn>
    </v-stepper-content>

    <v-stepper-step
      :complete="e6 > 3"
      step="3"
      @click="e6 = 3"
      >
      <h4>Risk Actions</h4>
    </v-stepper-step>

    <v-stepper-content step="3">
      <div v-if="riskManagementLevelSelector() == 'Future'">
        <v-label>N/A</v-label>
        <v-btn
          v-if="riskManagementLevelSelector() == 'Future'"
          @click="nullField('riskActions')"
          icon
          class="ml-2"
          ><v-icon v-if="riskActions[0] == 'N/A'" style="color: #00C851;">mdi-checkbox-marked-circle</v-icon><v-icon v-else>mdi-close-circle</v-icon>
        </v-btn>
      </div>
      <v-btn
        @click="addNewRiskAction"
        :disabled="checkNull('riskActions')"
        class="float-right ml-3 add-button-position mt-3"
        fab
        small
        color="primary"
        >
        <v-icon dark>
          mdi-plus
        </v-icon>
      </v-btn>

      <div 
        v-for="(field, index) in this.riskActions" 
        :key="index" 
        class="mb-4"
        >
        <v-combobox
          :search-input.sync="search"
          @input="search = ''"
          :disabled="checkNull('riskActions')"
          class="mt-3"
          v-model="field.causes"
          :items="mapCauses"
          item-text="text"
          item-value="value"
          chips
          clearable
          label="Select Causes"
          multiple
          prepend-icon="mdi-filter-variant"
          solo
          >
          <template v-slot:selection="{ attrs, item, select, selected }">
            <v-chip
              v-bind="attrs"
              :input-value="selected"
              close
              @click="select"
              @click:close="remove(item,index)"
              >
              <strong>{{ item.text }}</strong>&nbsp;
            </v-chip>
          </template>
        </v-combobox>
         
        <v-text-field 
          :disabled="checkNull('riskActions')"
          :placeholder="index !== 0 ? 'Input another Risk Action' : 'Input a Risk Action'"
          v-model="field.text"
          :append-icon="index !== 0 ? 'mdi mdi-delete' : ''"
          @click:append="deleteObjectiveInput(index, 'riskActions')"
          >
        </v-text-field>
      </div>

      <v-btn
        color="primary"
        @click="e6++"
        >
        Continue
      </v-btn>
      <v-btn @click="e6 -= 1">
        Back
      </v-btn>
    </v-stepper-content>

    <v-stepper-step
      :complete="e6 > 4"
      step="4"
      @click="e6 = 4"
      > 
      <h4>{{selectedRiskManagementLevel}} Risk Rating</h4>
    </v-stepper-step>

    <v-stepper-content step="4">
       <div v-if="riskManagementLevelSelector() == 'Future'">
        <v-label>N/A</v-label>
        <v-btn
          v-if="riskManagementLevelSelector() == 'Future'"
          @click="nullField('selectedLikelihood'); nullField('selectedImpactClassification'); nullField('selectedImpactLevel');"
          icon
          class="ml-2"
          ><v-icon v-if="selectedLikelihood == 'N/A'" style="color: #00C851;">mdi-checkbox-marked-circle</v-icon><v-icon v-else>mdi-close-circle</v-icon>
        </v-btn>
      </div>
      <v-row>
        <v-col v-if="riskManagementLevelSelector() == 'Residual'">
          <v-select
            :items="LikelihoodLevel"
            :disabled="checkNull('selectedLikelihood')"
            item-text="Level"
            item-value="Level"
            return-object
            :rules="returnRules('returnInherentLikelihood', 'Inherent Level', 'selectedLikelihood')"
            label="Select Likelihood Level"
            outlined
            v-model="selectedLikelihood"
            >
          </v-select>
        </v-col>

        <v-col v-else-if="riskManagementLevelSelector() == 'Future'">
          <v-select
            :items="LikelihoodLevel"
            :disabled="checkNull('selectedLikelihood')"
            item-text="Level"
            item-value="Level"
            return-object
            :rules="returnRules('returnResidualLikelihood', 'Residual Level', 'selectedLikelihood')"
            label="Select Likelihood Level"
            outlined
            v-model="selectedLikelihood"
            >
          </v-select>  
        </v-col>
      </v-row>
      <v-row  v-if="this.selectedLikelihood && this.selectedLikelihood != 'N/A'">
        <v-col cols="6" v-if="this.selectedLikelihood && this.selectedLikelihood != 'N/A'">
          <v-list v-if="this.selectedLikelihood && this.selectedLikelihood != 'N/A'">
            <v-list-item><h3>Frequency</h3></v-list-item>
            <v-list-item>
              {{selectedLikelihood.Frequency}}
            </v-list-item>
          </v-list>  
        </v-col>

        <v-col cols="6" v-if="this.selectedLikelihood">
          <v-list v-if="this.selectedLikelihood && this.selectedLikelihood != 'N/A'">
            <v-list-item><h3>Probability</h3></v-list-item>
            <v-list-item>
              {{selectedLikelihood.Probability}}
            </v-list-item>
          </v-list>  
        </v-col>
      </v-row>
      <v-row>
        <v-col v-if="riskManagementLevelSelector() == 'Residual'">
          <v-select
            :items="RiskImpactLevel"
            item-text="Level"
            item-value=".key"
            :disabled="checkNull('selectedImpactLevel')"
            :rules="returnRules('returnInherentImpact', 'Inherent Level', 'selectedImpactLevel')"
            return-object
            label="Select Impact Criteria Level"
            outlined
            v-model="selectedImpactLevel"
            >
          </v-select>
        </v-col>
        <v-col v-else-if="riskManagementLevelSelector() == 'Future'">
          <v-select
            :items="RiskImpactLevel"
            item-text="Level"
            item-value=".key"
            :disabled="checkNull('selectedImpactLevel')"
            :rules="returnRules('returnResidualImpact', 'Residual Level', 'selectedImpactLevel')"
            return-object
            label="Select Impact Criteria Level"
            outlined
            v-model="selectedImpactLevel"
            >
          </v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-select
            :items="ImpactArr"
            item-text="name"
            item-value="id"
            :disabled="checkNull('selectedImpactClassification')"
            label="Select Impact Classification"
            return-object
            outlined
            v-model="selectedImpactClassification"
            >
          </v-select>
        </v-col>
      </v-row>
          
      <v-row v-if="this.selectedLikelihood && this.selectedImpactLevel && selectedImpactClassification && this.selectedLikelihood != 'N/A'">
        <v-col>
          <v-list v-if="this.selectedLikelihood && this.selectedImpactLevel && selectedImpactClassification">
            <v-list-item><h3>Description</h3></v-list-item>
            <v-list-item 
              v-for="(impact, index) in this.displayImpact"
              :key="index" 
              >{{impact}}
            </v-list-item>
          </v-list>              
        </v-col>
        <v-col>
          <v-list v-if="this.selectedLikelihood && this.selectedImpactLevel && selectedImpactClassification">
            <v-list-item><h3>Vulnerability Rating</h3></v-list-item>
            <v-list-item>
              {{computeVulnerability}}
            </v-list-item>
          </v-list> 
        </v-col>
      </v-row>
      <v-btn
        color="primary"
        @click="e6++"
        >
        Continue
      </v-btn>
      <v-btn @click="e6 -= 1">
        Back
      </v-btn>
    </v-stepper-content>

    <v-stepper-step
      :complete="e6 > 5"
      step="5"
      @click="e6 = 5"
      >
      <h4>KPI</h4>
    </v-stepper-step>
    <v-stepper-content step="5">
      <div v-if="riskManagementLevelSelector() == 'Future'">
        <v-label>N/A</v-label>
        <v-btn
          v-if="riskManagementLevelSelector() == 'Future'"
          @click="nullField('kpiInputFields')"
          icon
          class="ml-2"
          ><v-icon v-if="kpiInputFields[0] == 'N/A'" style="color: #00C851;">mdi-checkbox-marked-circle</v-icon><v-icon v-else>mdi-close-circle</v-icon>
        </v-btn>
      </div>
      <v-btn
        @click="addNewField('kpiInputFields')"
        class="float-right mr-1 add-button-position"
        :disabled="checkNull('kpiInputFields')"
        fab
        small
        color="primary"
        >
        <v-icon dark>
          mdi-plus
        </v-icon>
      </v-btn>
      <v-text-field 
        v-for="(field, index) in this.kpiInputFields" 
        :key="index" 
        :disabled="checkNull('kpiInputFields')"
        :placeholder="index !== 0 ? 'Input another KPI' : 'Input a KPI'"
        v-model="field.text"
        :append-icon="index !== 0 ? 'mdi mdi-delete' : ''"
        @click:append="deleteObjectiveInput(index, 'kpiInputFields')"
        >
      </v-text-field>
      <v-btn
        color="primary"
        @click="e6++"
        >
        Continue
      </v-btn>
      <v-btn @click="e6 -= 1">
        Back
      </v-btn>
    </v-stepper-content>

    <v-stepper-step
      :complete="e6 > 6"
      step="6"
      @click="e6 = 6"
      >
      <h4>Timeline</h4>
    </v-stepper-step>
    <v-stepper-content step="6">
      <div v-if="riskManagementLevelSelector() == 'Future'">
        <v-label>N/A</v-label>
        <v-btn
          v-if="riskManagementLevelSelector() == 'Future'"
          @click="nullField('startDate'); nullField('endDate')"
          icon
          class="ml-2"
          ><v-icon v-if="startDate == 'N/A' && endDate == 'N/A'" style="color: #00C851;">mdi-checkbox-marked-circle</v-icon><v-icon v-else>mdi-close-circle</v-icon>
        </v-btn>
      </div>
      <v-row class="pl-4">
        <v-menu
          ref="menu1"
          v-model="menu1"
          :disabled="checkNull('startDate')"
          :close-on-content-click="false"
          :return-value.sync="startDate"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="startDate"
                label="Start Date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                >
              </v-text-field>
            </template>
            <v-date-picker
              type="month"
              v-model="startDate"
              no-title
              scrollable
              :min="getMin"
              >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="menu1 = false"
                >
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.menu1.save(startDate)"
                >
                OK
              </v-btn>
            </v-date-picker>
        </v-menu>
      </v-row>
      <v-row class="pl-4">
        <v-menu
          ref="menu2"
          v-model="menu2"
          :disabled="checkNull('endDate')"
          :close-on-content-click="false"
          :return-value.sync="endDate"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
          >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="endDate"
              label="End Date"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              >
            </v-text-field>
          </template>
          <v-date-picker
            type="month"
            v-model="endDate"
            no-title
            scrollable
            :min="getMin"
            >
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="menu2 = false"
              >
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.menu2.save(endDate)"
              >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-row>
      <v-row class="pt-4 pl-4">
        <v-btn
          color="primary"
          @click="e6++"
          >
          Continue
        </v-btn>
        <v-btn @click="e6 -= 1">
          Back
        </v-btn>
      </v-row>
    </v-stepper-content>
    
    <v-stepper-step
      :complete="e6 > 7"
      step="7"
      @click="e6 = 7"
      >
      <h4>Budget/Resources</h4>
    </v-stepper-step>
    <v-stepper-content step="7">
      <div v-if="riskManagementLevelSelector() == 'Future'">
        <v-label>N/A</v-label>
        <v-btn
          v-if="riskManagementLevelSelector() == 'Future'"
          @click="nullField('budgetInputFields')"
          icon
          class="ml-2"
          ><v-icon v-if="budgetInputFields[0] == 'N/A'" style="color: #00C851;">mdi-checkbox-marked-circle</v-icon><v-icon v-else>mdi-close-circle</v-icon>
        </v-btn>
      </div>
      <v-btn
        @click="addNewField('budgetInputFields')"
        class="float-right mr-1 add-button-position"
        :disabled="checkNull('budgetInputFields')"
        fab
        small
        color="primary"
        >
        <v-icon dark>
          mdi-plus
        </v-icon>
      </v-btn>
      <v-text-field 
        :disabled="checkNull('budgetInputFields')"
        v-for="(field, index) in this.budgetInputFields" 
        :key="index" 
        :placeholder="index !== 0 ? 'Input another Budget' : 'Input a Budget'"
        v-model="field.text"
        :append-icon="index !== 0 ? 'mdi mdi-delete' : ''"
        @click:append="deleteObjectiveInput(index, 'budgetInputFields')"
        >
      </v-text-field>
      <v-btn
        color="primary"
        @click="save()"
        >
        Save
      </v-btn>
      <v-btn @click="e6 -= 1">
        Back
      </v-btn>
    </v-stepper-content>
  </v-stepper>
</template>

<script>
export default {
  props: ['id'],
  firestore(){
    if(this.$route.path == '/risklevel/'+this.$route.params.id){
      return {
        ORMP: this.$db.collection('ORMP').doc(this.$route.params.id),
        ORMPLevels: this.$db.collection('ORMPLevels').where("ORMPId", "==", this.$route.params.id),
        ORMPLevelsInherent: this.$db.collection('ORMPLevels').where("ORMPId", "==", this.$route.params.id).where("RiskManagementLevel", "==", 'Inherent'),
        RiskStrategy: this.$db.collection('RiskStrategy'),
        RiskTreatmentOwner: this.$db.collection('RiskTreatmentOwner'),
        LikelihoodLevel: this.$db.collection('LikelihoodLevel').orderBy("Level", "desc"),
        RiskImpactLevel: this.$db.collection('RiskImpactLevel'),
        UserAccounts: this.$db.collection('UserAccounts'),
      }
    } else {
      return {
        PRMP: this.$db.collection('PRMP').doc(this.$route.params.id),
        PRMPLevels: this.$db.collection('PRMPLevels').where("PRMPId", "==", this.$route.params.id),
        PRMPLevelsInherent: this.$db.collection('PRMPLevels').where("PRMPId", "==", this.$route.params.id).where("RiskManagementLevel", "==", 'Inherent'),
        RiskStrategy: this.$db.collection('RiskStrategy'),
        RiskTreatmentOwner: this.$db.collection('RiskTreatmentOwner'),
        LikelihoodLevel: this.$db.collection('LikelihoodLevel').orderBy("Level", "desc"),
        RiskImpactLevel: this.$db.collection('RiskImpactLevel'),
        UserAccounts: this.$db.collection('UserAccounts'),
      }
    }
  },

  data(){
    return {
      e6: 1,
      search: '',
      menu2: false,
      menu1: false,
      riskManagamentLevel: [{id: 1, Level: 'Residual'}, {id: 2, Level: 'Future'}],
      selectedRiskManagementLevel: '',
      selectedRiskStrategy: '',
      KPI: '',
      startDate: '',
      endDate: '',
      budgetResources: '',
      type: 'Person', 
      riskActions: [
        {
          text: '',
          causes: []
        }
      ],
      kpiInputFields: [
        {
          text: ''
        }
      ],
      budgetInputFields: [
        {
          text: ''
        }
      ],
      selectedLikelihood: '',
      selectedImpactLevel: '',
      selectedImpactClassification: '',
      selectedriskTreatmentOwners: [],
      ImpactArr: [
        {id: 'Environment', name: 'Environment'},
        {id: 'HSS', name: 'Health, Safety & Security'},
        {id: 'LegalCompliance', name: 'Legal and Compliance'},
        {id: 'NIAT', name: 'Net Income After Tax'},
        {id: 'MGTACTION', name: 'Mgt Action'},
        {id: 'Operational', name: 'Operational'},
        {id: 'Reputation', name: 'Reputation'},
      ],
    }
  },

  methods: {
    checkNull(data){
      if(data == 'selectedRiskStrategy' || data == 'kpiInputFields' || data == 'budgetInputFields' || data == 'endDate' || data == 'startDate' || data == 'selectedLikelihood' || data == 'selectedImpactClassification' || data == 'selectedImpactLevel'){
        if(this[data] == 'N/A'){
          return true
        } else {
          return false
        }
      } else {
        if(this[data][0] == 'N/A'){
          return true
        } else {
          return false
        }
      }
    },

    nullField(data){
      if(data == 'selectedriskTreatmentOwners'){
        if(this[data] == 'N/A'){
          this[data] = []
        } else {
          this[data] = ['N/A']
        }
      } else if(data == 'riskActions' || data == 'kpiInputFields' || data == 'budgetInputFields'){
        if(this[data] == 'N/A'){
          this[data] = [
            {
              text: ''
            }
          ] 
        } else {
          this[data] = ["N/A"]
        }
      } else {
        if(this[data] == 'N/A'){
          this[data] = ''
        } else {
          this[data] = 'N/A'
        }
      }
    },
      
    mapInput(arr){
        if(this[arr] == 'N/A'){
          return this[arr]
        } else {
          let values = this[arr].map(function(value) {
            return value['text']
          });
          return values;
        }
    },

    mapMultiRTO(){
        let mapped = []
        this.selectedriskTreatmentOwners.forEach(e1=>{
        mapped.push({
          ['.key']: e1.text['.key'], 
          RiskTreatmentOwner: e1.text.RiskTreatmentOwner,
          type: e1.type
          })
        })
        return mapped   
    },

      allowedDates(val) {
        return !this.isDateinIRACoverage(val)
      },

      allowedDatesEnd(val) {
        return !this.isDateinIRACoverage(val) && val > this.startDate
      },

      formatName(user){
        let email = user.emailAdd
        let splitLast = email.split('@')
        let name = splitLast[0]
        let splitName = name.split('.')
        let firstname = splitName[0].charAt(0).toUpperCase() + splitName[0].slice(1)
        let lastname = splitName[1].charAt(0).toUpperCase() + splitName[1].slice(1)
        let fullname = firstname + ' ' + lastname
        return {['.key']: user['.key'], RiskTreatmentOwner: fullname}
      },

      riskManagementLevelSelector() {
        if(this.$route.path == '/risklevel/'+this.$route.params.id){
          let result;
          if(this.ORMPLevels.length == 0){
            return ''
          }  
          let mapped = this.ORMPLevels.map(e => {
            return e.RiskManagementLevel
          })
          if(mapped.length >= 2){
            result =  "Future"
          } else {
              result = "Residual"
          }
          return result
        } else {
          let result;
          if(this.PRMPLevels.length == 0){
              return ''
            }  
            let mapped = this.PRMPLevels.map(e => {
              return e.RiskManagementLevel
            })
            if(mapped.length >= 2){
              result =  "Future"
            } else {
              result = "Residual"
            }
              return result
          }
      },

      returnRules(func, level){
        return [value => (value.Level <= this[func]()) || 'Cannot be higher than previous ' + level]
      },

      save() {
        if(this.$route.path == '/risklevel/'+this.$route.params.id){
          let data = {
            ORMPId: this.$route.params.id,
            CoverageId: this.ORMP.CoverageId,
            RiskStrategy: this.selectedRiskStrategy,
            CreatedAt: this.$firebase.firestore.FieldValue.serverTimestamp(),
            CreatedBy: this.$store.getters['useraccount/isAuthenticated'].email,
            Department: this.$store.getters['useraccount/isAuthenticated'].department,
            Modified: this.$firebase.firestore.FieldValue.serverTimestamp(),
            RiskManagementLevel: this.riskManagementLevelSelector(),
            RiskActions: this.riskActions,
            LikelihoodLevel: this.selectedLikelihood,
            ImpactLevel: this.mapImpact(),
            ImpactClassification: this.selectedImpactClassification,
            RiskTreatmentOwner: this.selectedriskTreatmentOwners,
            KPI: this.mapInput('kpiInputFields'),
            TimelineStartDate: this.startDate,
            TimelineEndDate: this.endDate,
            Budget: this.mapInput('budgetInputFields'),
            Vulnerability: this.computeVulnerabilityMethod()
          }
          this.$emit('clicked', data)
        } else {
          let data = {
            PRMPId: this.$route.params.id,
            CoverageId: this.PRMP.CoverageId,
            RiskStrategy: this.selectedRiskStrategy,
            CreatedAt: this.$firebase.firestore.FieldValue.serverTimestamp(),
            CreatedBy: this.$store.getters['useraccount/isAuthenticated'].email,
            Department: this.$store.getters['useraccount/isAuthenticated'].department,
            Modified: this.$firebase.firestore.FieldValue.serverTimestamp(),
            RiskManagementLevel: this.riskManagementLevelSelector(),
            RiskActions: this.riskActions,
            LikelihoodLevel: this.selectedLikelihood,
            ImpactLevel: this.mapImpact(),
            ImpactClassification: this.selectedImpactClassification,
            RiskTreatmentOwner: this.selectedriskTreatmentOwners,
            KPI: this.mapInput('kpiInputFields'),
            TimelineStartDate: this.startDate,
            TimelineEndDate: this.endDate,
            Budget: this.mapInput('budgetInputFields'),
            Vulnerability: this.computeVulnerabilityMethod()
            }
            this.$emit('clicked', data)
          }
        },

      computeVulnerabilityMethod(){
        if(this.selectedLikelihood == 'N/A' && this.selectedImpactLevel == 'N/A'){
          return 'N/A'
        } else {
          return this.selectedLikelihood.Level * this.selectedImpactLevel.Level
        }
      },

      mapImpact() {
        let arr = this.RiskImpactLevel
        if(this.selectedImpactLevel === ''){
          return ''
        } else if (this.selectedImpactLevel == 'N/A'){
          return this.selectedImpactLevel
        } else {
          let filter = arr.filter(e => {
            return e['.key'] == this.selectedImpactLevel['.key']  
          }) 
          if(this.selectedImpactClassification === '') {
            return []
          } else {
              return {
                ['.key']:this.selectedImpactLevel['.key'], 
                Level:filter[0].Level, 
              }
            }
        }
      },

      mapMulti(arr, fieldName){
        let mapped = []
        this[arr].forEach(e1=>{
          mapped.push({
            ['.key']: e1.text['.key'], 
            [fieldName]:e1.text[fieldName]
          })
        })
        return mapped   
      },

      remove (item,index) {
        this.riskActions[index].causes.splice(this.riskActions[index].causes.indexOf(item), 1)
      },

      addNewRiskAction() {
        this.riskActions.push({text: ''})
      },

      deleteObjectiveInput(index, arrayName) {
        this[arrayName].splice(index, 1)
      },
        
      addNewField(arrayName) {
        this[arrayName].push({text: ''})
      },
        
      addNewFieldRTO() {
        this.selectedriskTreatmentOwners.push({text: '', type: 'Person'})
      },

      returnInherentLikelihood(){
        if(this.$route.path == '/risklevel/'+this.$route.params.id){
          let filter = this.ORMPLevels.filter(e=>{
          return e.RiskManagementLevel == 'Inherent'
          })
          return filter[0].LikelihoodLevel.Level
        } else {
          let filter = this.PRMPLevels.filter(e=>{
          return e.RiskManagementLevel == 'Inherent'
          })  
          return filter[0].LikelihoodLevel.Level
        }
      },
      
      returnResidualLikelihood(){
        if(this.$route.path == '/risklevel/'+this.$route.params.id){
          let filter = this.ORMPLevels.filter(e=>{
          return e.RiskManagementLevel == 'Residual'
          })
          if(filter){
            return filter[0].LikelihoodLevel.Level
          }
        } else {
          let filter = this.PRMPLevels.filter(e=>{
          return e.RiskManagementLevel == 'Residual'
          })
          if(filter){
            return filter[0].LikelihoodLevel.Level
          }
        }
      },
      
      returnInherentImpact(){
        if(this.$route.path == '/risklevel/'+this.$route.params.id){
          let filter = this.ORMPLevels.filter(e=>{
          return e.RiskManagementLevel == 'Inherent'
          })
          return filter[0].ImpactLevel.Level
          } else {
            let filter = this.PRMPLevels.filter(e=>{
            return e.RiskManagementLevel == 'Inherent'
            })   
            return filter[0].ImpactLevel.Level
          }
        },
      
      returnResidualImpact(){
        if(this.$route.path == '/risklevel/'+this.$route.params.id){
          let filter = this.ORMPLevels.filter(e=>{
            return e.RiskManagementLevel == 'Residual'
          })
          return filter[0].ImpactLevel.Level
        } else {
          let filter = this.PRMPLevels.filter(e=>{
            return e.RiskManagementLevel == 'Residual'
          })
          return filter[0].ImpactLevel.Level
        }
      },

      mapInputArr(){
        if(this.$route.path == '/risklevel/'+this.$route.params.id){
          if(this.ORMP.length === 0){
            return []
          } else {
            let i
            let arr = this.ORMP.Causes
            let mapThis = []
            for(i=0; i < arr.length; i++) {
              mapThis.push({text: arr[i]})
            }
            return mapThis 
          }
        } else {
          if(this.PRMP.length === 0){
            return []
          }
          let i
          let arr = this.PRMP.Causes
          let mapThis = []
          for(i=0; i < arr.length; i++) {
           mapThis.push({text: arr[i]})
          }
          return mapThis 
        }
      },
    },

    computed: {
      returnRiskTreatmentOwner(){
        if(this.RiskTreatmentOwner.length == 0){
          return []
        } 
        let arr = this.RiskTreatmentOwner
        let newArr = []
        arr.forEach(e=>{
          let x = {...e}
          x.Type = 'Department'
          newArr.push(x)
        })
        return newArr
      },  
      
      returnDepartmentMembers(){
        let arr = []
        if(this.UserAccounts.length == 0){
         return []
        }
        let filtered = this.UserAccounts.filter(e=>{
         return e.department == this.$store.getters['useraccount/isAuthenticated'].department
        })
        filtered.forEach(e=>{
         arr.push(this.formatName(e))
        })
        return arr
      },
      
      getMin() {
        // var d = new Date(new Date().getFullYear() + 1, 0, 2);
        var d = new Date();
        console.log(d.toISOString().substring(0, 7))
        return d.toISOString().substring(0, 7);
      },

      computeVulnerability() {
        return this.selectedLikelihood.Level * this.selectedImpactLevel.Level
      },

      displayImpact() {
        let id = this.selectedImpactClassification.id
        let arr = this.RiskImpactLevel
        if(this.selectedImpactLevel === '' || this.selectedImpactLevel == 'N/A'){
          return []
        } else {
          let filter = arr.filter(e => {
          return e['.key'] == this.selectedImpactLevel['.key']
          })      
          return filter[0][id]    
        }
      },

      mapCauses() {
        if(this.mapInputArr().length === 0){
          return []
        }
        let map = this.mapInputArr().map(function(currentValue, index){
          return {text:currentValue.text, value: index}
          })
          return map
        },

      check() {
        return this.mapCauses
      }
    }
}
</script>

<style scoped>
h4:hover{
  cursor: pointer;
  color: gray;
}
h4{
  font-weight: normal;
}
</style>